import useCaseAdapter from "../../../Common/domain/useCases/useCaseAdapter";
import ProductAPI from "../../infraestructure/services/ProductAPI";
import APIProductRepository from '../../infraestructure/repository/APIProductRepository'
import { IProduct } from "../../domain/dto/ProductDTO";
import listProductsUseCase from "../useCases/listProducts";

export interface IProductQueryInputParams {
    page:number
}

export interface IProductCatalogPromise {
    list:Promise<Array<IProduct>>|[],
    viewing:Promise<Array<IProduct>>|[],
}

export class listProductsAdapter implements useCaseAdapter {

    private productList:IProductCatalogPromise = {
        list:[],
        viewing:[]
    }

    constructor(private inputParams:IProductQueryInputParams){
        this.list();
    }

    async list() {

        const api = ProductAPI.getInstance(process.env.REACT_APP_API_URL || '');
        const repo = new APIProductRepository(api);

        const _listProductUseCase = new listProductsUseCase(this.inputParams, repo);
        _listProductUseCase.execute();

        const {list, viewing} = _listProductUseCase.result();
        this.productList.list = list;
        this.productList.viewing = viewing;
        
    }

    output():IProductCatalogPromise{
        return this.productList
    }

}