import * as config_ from './firebase-credentials.json'
import * as config_PRD from './firebase-credentials-prd.json'

const configs:any = {
    /* "development": config_, */
    "development": config_PRD,
    "production": config_PRD
}

//const cfg = configs[process.env.NODE_ENV].default;
const cfg = configs["production"].default;

const config = {
    firebase: {
        apiKey: cfg.apiKey,
        authDomain: cfg.authDomain,
        projectId: cfg.projectId,
        storageBucket: cfg.storageBucket,
        messagingSenderId: cfg.messagingSenderId,
        appId: cfg.appId
    }
}

export default config;