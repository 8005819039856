import IuseCase from "../../../Common/domain/useCases/IuseCase";
import { ACShoppingCartRepository } from "../../domain/repository/shoppinCart";
import { IShoppingCart } from "../../domain/contract/ShoppingCart";

export default class getSavedCartUseCase implements IuseCase {

    private savedCart:object = {};

    constructor(private cartRepository:ACShoppingCartRepository){

    }

    execute():void{
        this.savedCart = this.cartRepository.recover('shoppingCart');
    }

    result():IShoppingCart{
        return this.savedCart as IShoppingCart;
    }

}