import IuseCase from "../../../Common/domain/useCases/IuseCase";
import { IOrder } from "../../domain/domain/order";
import { ACOrderRepository } from "../../domain/repository/ACOrderRepository";

export default class PlaceOrderUseCase implements IuseCase {
    
    private placedOrder:object = {}

    constructor(private orderData:IOrder, private orderRepository:ACOrderRepository){

    }

    execute():void{
        this.placedOrder = this.orderRepository.place(this.orderData)
    }

    result():IOrder{
        return this.placedOrder as IOrder;
    }
}