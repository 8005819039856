import React, {FC} from 'react';
import LoginOptionsComponent from '../organisms/LoginOptionsComponent';

const AuthPage:FC<any> = () => {
    
    return (<div className="content-wrapper"> 

        <div className="auth-container">
            <LoginOptionsComponent />
        </div>

    </div>);
}

export default AuthPage;