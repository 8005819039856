import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import config from './config';


const appCheckToken = process.env.REACT_APP_FIREBASE_APPCHECK_TOKEN || '';
// console.log("appCheckToken", appCheckToken);

const Firebase = firebase.initializeApp(config.firebase);

const appCheck = initializeAppCheck(Firebase, {
    provider: new ReCaptchaV3Provider(appCheckToken),
    isTokenAutoRefreshEnabled: true,
    
});

// console.log("appCheck", appCheck);

// Add or Remove authentification methods here.
export const Providers = {
    google: new firebase.auth.GoogleAuthProvider()
};

export const auth = Firebase.auth();
export const firestore = Firebase.firestore();
export const analytics = Firebase.analytics();

export default Firebase;


export const SignInWithSocialMedia = (provider: firebase.auth.AuthProvider) =>
    new Promise<firebase.auth.UserCredential>((resolve, reject) => {
        
        auth.signInWithPopup(provider)
            .then((result:any) => {
                resolve(result)
            })
            .catch(error => reject(error));
});


export const createAccountWithEmailAndPassword = (email:string, password:string) => 

    new Promise<firebase.auth.UserCredential>((resolve, reject) => {
        
        auth.createUserWithEmailAndPassword(email, password).then((userCredential) => {

            resolve(userCredential);
            
          }).catch((error) => reject(error));
        
});


export const loginWithEmailAndPassword = (email:string, password:string) => 

    new Promise<firebase.auth.UserCredential>((resolve, reject) => {
        
        auth.signInWithEmailAndPassword(email, password).then((userCredential) => {

            resolve(userCredential);
            
          }).catch((error) => reject(error));
        
});

export const resetPassword = (email:string) => 

    new Promise<void>((resolve, reject) => {
        
        auth.sendPasswordResetEmail(email).then((res) => {

            resolve(res);
            
          }).catch((error) => reject(error));
        
});


export const verifyCodePassword = (code:string) => 

    new Promise<void>((resolve, reject) => {
        
        auth.applyActionCode(code).then((res) => {

            resolve(res);
            
        }).catch((error) => reject(error));
        
});

export const refreshAccessToken = async () => {

    const refreshToken = await auth.currentUser?.getIdToken(true).then(res=>res).catch(err => {
        throw new Error("Error refreshing auth token");
    });

    const currentUserInfo:string|null = localStorage.getItem('userInfo')
    
    if(currentUserInfo === null){
        return false;
    }
    
    const currentUserInfoJSON = JSON.parse(currentUserInfo);
    const newUserInfo = {...currentUserInfoJSON, accessToken: refreshToken}
    localStorage.setItem('userInfo', JSON.stringify(newUserInfo));

    return refreshToken;
}


export const getRequestsHeaders = ():any => {

    try{

        const userInfo:any = localStorage.getItem('userInfo');

        if(!userInfo) {
            return {};
        }

        const userInfoJSON = JSON.parse(userInfo);
        const token:string = userInfoJSON["accessToken"];

        return {
            "headers": {
                "Authorization": `Bearer ${token}`
            }
        }

    } catch(e) {

        console.error(e);
        return {}

    }
}

export const onAuthStateChange = (cb:any) => {
    return auth.onAuthStateChanged(user => {
      if (user) {
        /* console.log("The user is logged in"); */
        try {
            analytics.setUserId(user.uid);
        } catch(e) {
            console.error("analytics.setUserId(user.uid)", e);
        }
        cb(true);
      } else {
        /* console.log("The user is not logged in"); */
        cb(false);
      }
    });
}