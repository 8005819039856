import { refreshAccessToken } from "../../../Common/infrastructure/providers/auth/firebase/firebase";
import { getRequestsHeaders } from "../../../Common/infrastructure/providers/auth/firebase/firebase";
import { firestore } from "../../../Common/infrastructure/providers/auth/firebase/firebase";
import { getUserId } from "../../../Clients/application/useCases/getUserIdUseCase";
import axios from "axios";

export const ViewOrderDetailUseCase = async (id:number) => {
    
    if(!id) {
        return {}
    }

    try {

        const userProviderId =  getUserId();

        if(userProviderId !== 0) {

            const userOrderFromFirestore = await firestore.collection("users").doc(userProviderId).collection('orders').doc(String(id)).get();
            const orderData:any = userOrderFromFirestore.data();

            console.log("orderData", orderData);

            if(Object.values(orderData).length > 0) {

                return {
                    "id": orderData?.firestoreOrderId,
                    "CorreoClient": orderData?.email,
                    "Nombre": orderData?.name,
                    "DireccionEntrega": orderData?.address,
                    "ComunaEntrega": orderData?.comuna,
                    "Celular": orderData?.phone,
                    "PayloadPedido": orderData?.items,
                    "RUT": orderData?.rut,
                    "Ciudad": orderData?.city,
                    "ReferenciasDireccion": orderData?.address_reference || '',
                    "TipoCliente": orderData?.client_type,
                    "QuiereRecibirEmails": false,
                    "ComoConocioSusaron": '',
                    "TotalAprox": orderData?.total_aprox,
                    "TotalFinal": orderData?.total || 0,
                    "EstadoOrden": orderData?.order_status || 'RECIBIDA',
                    "NumeroCalle": orderData?.addressNumber,
                    "VillaBlockDepto": orderData?.addressExtra,
                    "PersonaContactoDelivery": orderData?.deliveryContactName || '',
                    "TelefonoContactoDelivery": orderData?.deliveryContactPhone || '',
                    "RelatedCommand": '',
                    "ProcesadoPorSusaron": orderData?.order_processed || false,
                    "Apellido": orderData?.lastname || '',
                    "TotalDespacho": orderData?.deliveryCostValue || 0,
                    "published_at": orderData?.published_at || (new Date(Number(orderData?.firestoreOrderId)) ?? undefined),
                    "created_at": orderData?.created_at ||  (new Date(Number(orderData?.firestoreOrderId)) ?? undefined),
                    "updated_at": orderData?.updated_at ||  (new Date(Number(orderData?.firestoreOrderId)) ?? undefined)
                }
            }

        }

        await refreshAccessToken();

        const URL = `${process.env.REACT_APP_VIEW_ORDER}/${id}/`;
        const headers = getRequestsHeaders();
        const order  = await axios.get(URL, headers).then(res => res).catch(e => {
            throw new Error(e);
        });

        return order.data;

    } catch(e) {

        console.error(e);
        alert("Hubo un problema al obtener los datos de la orden " + id);
        return {};

    }
}