import React,{ FC, useContext, useEffect, useState } from "react"
import {Link} from 'react-router-dom'
import { ADD_TO_CART_ACTION, StoreContext } from "../../../../store/AppContent";
import NumberFormat from 'react-number-format';
import { getSavedCartAdapter } from "../../../application/adapters/getSavedCartAdapter";
import { removeProductFromCartAdapter } from "../../../application/adapters/removeProductFromCart";
import { incrementProductQtyAdapter } from "../../../application/adapters/incrementProductQty";
import { decrementProductQtyAdapter } from "../../../application/adapters/decrementProductQtycopy";
import { IShoppingCartProduct } from "../../../domain/contract/ShoppingCartProduct";
import SetCartProductQuantityComponent from "./SetCartProductQuantity";
import Snackbar from '@mui/material/Snackbar';


interface ISideCartOptData {
    deliveryCost?:number|undefined;
}


const SideCart:FC<ISideCartOptData> = (props)=> {

    const { shoppingCart } = useContext(StoreContext);
    const {deliveryCost} = props;
    const [showSnackbar, setShowSnackbar] = useState(false);

    const loadAppData = () => {
        const savedCartAdapter = new getSavedCartAdapter();
        const savedCart = savedCartAdapter.output();
        shoppingCart.shoppingCartDispatch({
          type: ADD_TO_CART_ACTION,
          payload: savedCart,
        });
    }


    const removeFromCart = (product:IShoppingCartProduct) => {
        
        const removeAdapter = new removeProductFromCartAdapter(product);
        const result = removeAdapter.output();
        
        shoppingCart.shoppingCartDispatch({
            type: ADD_TO_CART_ACTION,
            payload: result,
        });

        setShowSnackbar(true);
    }

    const increaseProductQuantity = (product:any) => () => {

        const incrementAdapter = new incrementProductQtyAdapter(product);
        
        shoppingCart.shoppingCartDispatch({
            type: ADD_TO_CART_ACTION,
            payload: incrementAdapter.output(),
        });

    }

    const decreaseProductQuantity = (product:any) => () => {

        const decrementAdapter = new decrementProductQtyAdapter(product);
        
        shoppingCart.shoppingCartDispatch({
            type: ADD_TO_CART_ACTION,
            payload: decrementAdapter.output(),
        });
    }


    useEffect(()=>{
        loadAppData();
        return () => {}
    },[])

    if(shoppingCart.shoppingCart.products.length===0) {
        return (<>
        
            <h2 className="empty-cart-message">Tu carrito está vacío.</h2>

            <div id="side-cart-duplicate-order-wrapper">

                <p className="empty-cart-message-duplicate-order">
                    Si ya realizaste pedidos con Susaron, puedes repetir cualquier pedido entrando <span className="link-to-orders-from-cart">aquí.</span>
                </p>
                
                <div className="spacer h30">&nbsp;</div>

                <Link className="button reverse duplicate-order" to="/account/orders/">
                    Repetir pedido anterior
                </Link>

            </div>
        
        </>)
    }

    return (
        <div className="side-cart-container">
            <h2 className="side-cart-title">Productos seleccionados</h2>
            <ul className="side-cart">
                {shoppingCart.shoppingCart.products.map((prod, index) => 
                
                <li key={index}>
                    
                    <div className="side-cart-row">
                        
                        <div className="cart-product-title">
                            <h5>{prod.qty} x {prod.name}</h5>
                        </div>
                        
                        <p className="cart-product-detail">
                            Precio unitario: <NumberFormat decimalPrecision={0} decimalSeparator=',' displayType="text" value={prod.price} thousandSeparator="." prefix={'$'} /> x {prod.measure_unit}<br/> 
                            Total: <NumberFormat decimalPrecision={0} decimalSeparator=',' displayType="text" value={Math.round(prod.qty* (prod.price * prod.unit_multiplier))} thousandSeparator="." prefix={'$'} />
                        </p>                        

                        <SetCartProductQuantityComponent {...{
                            name: prod.name,
                            productId: prod.id,
                            price: prod.price,
                            image: prod.image,
                            currentStock: 999,
                            increaseFunc: increaseProductQuantity(prod),
                            decreaseFunc: decreaseProductQuantity(prod),
                            cartCurrentQuantity: prod.qty
                        }} />

                        <div className="remove-from-cart">
                            <button value={prod.id} onClick={(e:any) => removeFromCart(prod)}>&#10006;</button>
                        </div>

                    </div>
                    
                </li>)
                
                }
            </ul>
            <div className="side-cart-total">
                
                <div className="total-price-row">
                    <h3>Total aprox.</h3>
                    <h3 className="price">
                        <NumberFormat decimalPrecision={0} decimalSeparator=',' displayType="text" value={shoppingCart.shoppingCart.totalPrice} thousandSeparator="." prefix={'$'} />
                    </h3>
                </div>

                {deliveryCost !== undefined && 
                <div className="total-price-row">
                    <h3>Costo envio.</h3>
                    <h3 className="price">
                        <NumberFormat decimalPrecision={0} decimalSeparator=',' displayType="text" value={deliveryCost} thousandSeparator="." prefix={'$'} />
                    </h3>
                </div>}

                {deliveryCost !== undefined && 
                <div className="total-price-row">
                    <h3>Total</h3>
                    <h3 className="price">
                        <NumberFormat decimalPrecision={0} decimalSeparator=',' displayType="text" value={deliveryCost + shoppingCart.shoppingCart.totalPrice} thousandSeparator="." prefix={'$'} />
                    </h3>
                </div>}

                <p className="aprox-values-info">
                    Recuerda que el valor es aproximado, ya que las piezas son cortadas y pesadas una vez que recibimos tu pedido.
                </p>
                

                <div className="checkout-button">
                    <Link className="sidecart-go-to-checkout" to="/checkout">
                        Ir a pedido
                    </Link>
                </div>

            </div>

            {showSnackbar && <Snackbar
                sx = {{mt: '120px'}}
                open={showSnackbar}
                autoHideDuration={2000}
                onClose={(e:any) => setShowSnackbar(false)}
                message="Producto sacado del carrito"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />}

        </div>
    )

}

export default SideCart