import React, {FC} from 'react'
import NumberFormat from 'react-number-format';

import { IProduct } from '../../../domain/dto/ProductDTO'

import CookingOptionComponent from '../atoms/CookingOption'
import IImageComponent from '../../../../Common/infrastructure/components/atoms/Image'
import TitleComponent from '../../../../Common/infrastructure/components/atoms/Title'
import AddToCartComponent from '../../../../Cart/infraestructure/components/molecules/AddToCart'

const ProductBox:FC<IProduct> = (productBox:IProduct) => {

    const {image, name, price, current_stock, id, cooking_options, sku, measure_unit, unit_multiplier} = productBox;

    let productTitle = '';
    try {

        // Lowercase title and after capitalize first letter
        productTitle = name.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');

    } catch(err) {
        
        productTitle = name;
    }

    const productNameProps = {
        type: 'h4',
        text: productTitle
    }

    const addToCartProps = {
        currentStock: current_stock,
        productId: id,
        image,
        price,
        name,
        sku,
        measure_unit,
        unit_multiplier
    }

    return (<div className="product-box">

        <div className="product-image">

            <div className='img-desktop'>
                <IImageComponent {...image} />
            </div>

            <div className='img-responsive'>
                <IImageComponent {...image} />
            </div>

            <ul className="cooking-options">
                {cooking_options.map((option, key) => <li key={key}><CookingOptionComponent {...{option: option}} /></li>)}
            </ul>

        </div>

        <div className="product-title">
            <TitleComponent {...productNameProps} />
        </div>

        <div className="product-price">
            <h5>
                <NumberFormat decimalPrecision={0} decimalSeparator=',' displayType="text" value={price} thousandSeparator="." prefix={'$'} />
                <br/>
                <small className="measure-unit">({measure_unit})</small>
            </h5>
        </div>

        <AddToCartComponent {...addToCartProps} />

    </div>)
}

export default ProductBox