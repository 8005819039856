import InputComponent from "../../../../Common/infrastructure/components/atoms/Input";
import {IInput} from '../../../../Common/infrastructure/components/atoms/Input';


export interface IRutInputComponent {
    rutState:string,
    rutOnChangeHandler:any
}

function checkRut(rut:string) {
    // Despejar Puntos
    let valor:string = rut.replace(/\./g,'');
    // Despejar Guión
    valor = valor.replace('-','');
    
    // Aislar Cuerpo y Dígito Verificador
    const cuerpo = valor.slice(0,-1);
    let dv:string|number = valor.slice(-1).toUpperCase();
    
    // Formatear RUN
    //rut.value = cuerpo + '-'+ dv
    
    // Si no cumple con el mínimo ej. (n.nnn.nnn)
    if(cuerpo.length < 7) { 
        return false;
    }
    
    // Calcular Dígito Verificador
    let suma = 0;
    let multiplo = 2;
    
    // Para cada dígito del Cuerpo
    for(let i=1;i<=cuerpo.length;i++) {
    
        // Obtener su Producto con el Múltiplo Correspondiente
        let index:number = multiplo * parseInt(valor.charAt(cuerpo.length - i));
        
        // Sumar al Contador General
        suma = suma + index;
        
        // Consolidar Múltiplo dentro del rango [2,7]
        if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }
  
    }
    
    // Calcular Dígito Verificador en base al Módulo 11
    const dvEsperado:number = 11 - (suma % 11);
    
    // Casos Especiales (0 y K)
    dv = (dv === 'K') ? 10: dv;
    dv = (dv === '0') ? 11: dv;

    let dv2:number;

    if(typeof dv === 'string') {
        dv2 = parseInt(dv);
    } else {
        dv2 = dv;
    }

    
    
    // Validar que el Cuerpo coincide con su Dígito Verificador
    if(dvEsperado !== dv2) { 

        console.log("if(dvEsperado !== dv)", typeof dvEsperado, dvEsperado, typeof dv, dv);
        return false; 
    }

    return true;
}

const RutInputComponent = (props:IRutInputComponent) => {

    const {rutState, rutOnChangeHandler} = props;
    
    const rutOnBlurHandler = (e:any) => {
        const rut = e.target.value;
        
        if(!checkRut(rut)){
            alert('Rut inválido');
            rutOnChangeHandler('');
            return;
        }

        const rutLimpio = rut.replace(/\./g,'').replace(/-/g,'');
        const digito = rutLimpio.slice(-1);
        const rutWithoutLastCharacter = rutLimpio.slice(0, -1);
        const newRut = `${rutWithoutLastCharacter}-${digito}`;
        rutOnChangeHandler(newRut);
        return;

    }

    const rutFieldProps:IInput = {
        fieldValue: rutState,
        onChangeFunc:rutOnChangeHandler,
        onBlurFunc:rutOnBlurHandler,
    }

    return <>
        <InputComponent {...rutFieldProps} />
    </>

}

export default RutInputComponent;
