import React, {FC, useState, useContext} from 'react';
import { ADD_TO_CART_ACTION, StoreContext } from '../../../../store/AppContent';
import { addToCartAdapter } from '../../../application/adapters/addToCartAdapter';

import ButtonComponent from '../../../../Common/infrastructure/components/atoms/Button';
import IImageComponent, { IImage } from '../../../../Common/infrastructure/components/atoms/Image';
import { IShoppingCartProduct } from '../../../domain/contract/ShoppingCartProduct';

import Snackbar from '@mui/material/Snackbar';

export interface IAddToCartComponent {
    currentStock: number;
    productId: number;
    image:IImage;
    name:string;
    sku:string;
    price:number;
    measure_unit:string;
    unit_multiplier:number;
}

const AddToCartComponent:FC<IAddToCartComponent> = (props:IAddToCartComponent) => {

    const {productId, currentStock, name, price, image, sku, measure_unit, unit_multiplier} = props;
    const [currentQuantity, setCurrentQuantity] = useState<number>(1);
    const { shoppingCart } = useContext(StoreContext);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const setter = (stateSetter:any):Function => (value:any):Number => {
        if(value<1){
            return currentQuantity
        }
        return stateSetter(value)
    }

    const buttonAdd = {
        text: "+",
        buttonProps: {
            onClick: ():Function => setter(setCurrentQuantity)(currentQuantity+1),
            className: "add-to-cart-button"
        }
    }

    const buttonDecrease = {
        text: "-",
        buttonProps: {
            onClick: ():Function => setter(setCurrentQuantity)(currentQuantity-1),
            className: "add-to-cart-button"
        }
    }

    const addToCart:Function = () => (productId:number, currentStock:number, currentQuantity:number, unitMultiplier:number) => {

        const _addToCartPayload:IShoppingCartProduct = {
            id:productId,
            sku,
            name,
            qty:currentQuantity,
            price,
            image: image.src,
            measure_unit,
            in_cart_qty:currentQuantity,
            unit_multiplier: unitMultiplier
        }

        const _addToCartAdapter = new addToCartAdapter(_addToCartPayload);
        const addToCartResult = _addToCartAdapter.output();

        shoppingCart.shoppingCartDispatch({
            type: ADD_TO_CART_ACTION,
            payload: addToCartResult,
        });

        setCurrentQuantity(1);
        setShowSnackbar(true);
    }

    const carrito:IImage = {
        alt:"Susaron",
        height: 31,
        width: 25,
        src:"/static/carrito.svg"
    }

    return (<div className="add-to-cart">

        <ul>
            <li>
                <ButtonComponent {...buttonDecrease} />
            </li>
            <li>
                <span className="add-to-cart-quantity">{currentQuantity}</span>
            </li>
            <li>
                <ButtonComponent {...buttonAdd} />
            </li>
            <li onClick={(e:any)=>addToCart()(productId, currentStock, currentQuantity, unit_multiplier)} className="basket-icon">
                <IImageComponent {...carrito} /> <span>Agregar</span>
            </li>
        </ul>

        {showSnackbar && <Snackbar
                sx = {{mt: '120px'}}
                open={showSnackbar}
                autoHideDuration={2000}
                onClose={(e:any) => setShowSnackbar(false)}
                message="Producto agregado al carrito"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />}

    </div>)
}

export default AddToCartComponent