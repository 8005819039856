import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Header from './Common/infrastructure/components/organisms/Header';
import Footer from './Common/infrastructure/components/organisms/Footer';
import Catalog from './Common/infrastructure/components/pages/Catalog';
import Home from './Common/infrastructure/components/pages/Home';
import Cart from './Common/infrastructure/components/pages/Cart';
import Checkout from './Common/infrastructure/components/pages/Checkout';
import AppContextProvider from './store/AppContent';
import AuthPage from './Common/infrastructure/components/pages/Auth';
import UserDetailPage from './Common/infrastructure/components/pages/userAccount/userData';
import OrdersPage from './Common/infrastructure/components/pages/userAccount/Orders';
import LogoutPage from './Common/infrastructure/components/pages/userAccount/Logout';
import OrderDetailPage from './Common/infrastructure/components/pages/userAccount/OrderDetail';
import SignUpPage from './Common/infrastructure/components/pages/userAccount/signUp';
import OrderPlacedPage from './Common/infrastructure/components/pages/OrderPlaced';
import SiteInformationPage from './Common/infrastructure/components/pages/SiteInformation';
import ContactPage from './Common/infrastructure/components/pages/Contact';
import ShippingZonesPage from './Common/infrastructure/components/pages/ShippingZones';
import PasswordRecoveryPage from './Common/infrastructure/components/pages/PasswordRecovery';
import PasswordResetPage from './Common/infrastructure/components/pages/PasswordReset';

function App() {

  document.title = "Susaron a su casa";

  /* console.log(process.env.NODE_ENV)
  console.log(process.env.REACT_APP_GATEWAYAPI_URL)
  console.log(process.env.REACT_APP_UPDATE_USER_INFORMATION)
  console.log(process.env.REACT_APP_VIEW_USER_INFORMATION)
  console.log(process.env.REACT_APP_EMAIL_PASSWORD_REGISTER)
  console.log(process.env.REACT_APP_LOGIN_OPTION_REGISTER)
  console.log(process.env.REACT_APP_GET_COMUNAS)
  console.log(process.env.REACT_APP_GET_DELIVERY_COST)
  console.log(process.env.REACT_APP_LIST_CLIENT_ORDERS)
  console.log(process.env.REACT_APP_VIEW_ORDER)
  console.log(process.env.REACT_APP_PLACE_ORDER) */

  return (
    <AppContextProvider>
      
    <Router>
      
      <header>
        <Header />
      </header>
      
      
      <div id="content-wrapper">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/productos" exact component={Catalog} />
          <Route path="/productos/:category" exact component={Catalog} />
          <Route path="/cart" exact component={Cart} />
          <Route path="/checkout" exact component={Checkout} />
          <Route path="/order-placed" exact component={OrderPlacedPage} />
          <Route path="/signUp" exact component={SignUpPage} />
          <Route path="/login" exact component={AuthPage} />
          <Route path="/password-recovery" exact component={PasswordRecoveryPage} />
          <Route path="/password-reset" exact component={PasswordResetPage} />
          <Route path="/contact" exact component={ContactPage} />
          <Route path="/about" exact component={SiteInformationPage} />
          <Route path="/shipping-zones" exact component={ShippingZonesPage} />
          <Route path="/account/" exact component={UserDetailPage} />
          <Route path="/account/orders" exact component={OrdersPage} />
          <Route path="/account/orders/:id/" exact component={OrderDetailPage} />
          <Route path="/account/logout" exact component={LogoutPage} />
        </Switch>
      </div>
      

      <footer>
        <Footer />
      </footer>


    </Router>

    
    </AppContextProvider>
  );
}

export default App;
