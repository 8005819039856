import React, {FC, useContext, useEffect, useState} from 'react';
import IImageComponent, {IImage} from '../atoms/Image';
import {Link} from 'react-router-dom';
import { listProductsAdapter } from '../../../../Product/application/adapters/listProductsAdapter';
import { StoreContext } from '../../../../store/AppContent';
import { IProduct, IProductCategory } from '../../../../Product/domain/dto/ProductDTO';
import { IProductCatalog } from '../../../../Product/domain/contracts/IProductCatalog';
import SearchBox from '../molecules/SearchBox';
import HeaderSkeleton from './HeaderSkeleton';
import MenuMobile from './MenuMobile';
import { useLocation } from "react-router-dom";
import { firestore } from '../../providers/auth/firebase/firebase';
import axios from 'axios';


const Header:FC<any> = (props:any) => {

    const { catalog, auth, shoppingCart } = useContext(StoreContext);
    const [categories,setCategories] = useState<Array<IProductCategory>>([]);
    const [profilePhoto, setProfilePhoto] = useState<string>('/static/perfil.svg');
    const [userFullName, setUserFullName] = useState<string>('Inicie sesión');
    const [responsiveMenuVisibilitiClass, setResponsiveMenuVisibilitiClass] = useState<string>('');
    const { pathname } = useLocation();

    const setResponsiveMenuVisibility = (e:any) => {

        if(responsiveMenuVisibilitiClass === 'show'){
            setResponsiveMenuVisibilitiClass('');
            window.scrollTo(0, 0);
        } else {
            setResponsiveMenuVisibilitiClass('show');
        }

    }


    useEffect(()=>{
        
        if(auth?.user?.photoURL && auth.user.photoURL  !== '') {
            setProfilePhoto(auth.user.photoURL); 
        } else {
            setProfilePhoto('/static/perfil.svg'); 
        }

    },[auth.user.photoURL])


    useEffect(()=>{
        
        if(auth.user.displayName !== null) {
            setUserFullName(auth.user.displayName); 
        }

    },[auth.user.displayName])


    useEffect(()=>{

        const getProducts = async () => {

            const firestoreCatalog:any = await firestore.collection('catalog').get().then(res => res).catch(err => {
                console.error("Error al obtener el catálogo de productos desde firestore", err);
                return err
            });


            if(Array.isArray(firestoreCatalog?.docs) && firestoreCatalog?.docs.length > 0) {

                const firestoreProductCatalog:Array<IProduct|null> = firestoreCatalog.docs.map((doc:any) => {
                    
                    /* console.log("prdData from firestore",doc.data()); */

                    const imagen:IImage = {
                        src: doc.data().Imagen["url"],
                        alt: doc.data().NombreProducto,
                        height: 200,
                        width: 200
                    }
                    const prdData = doc.data();

                    if(prdData.catJSON === undefined || Object.keys(prdData.catJSON).length === 0){
                        return null;
                    }

                    return {
                        id: prdData.id,
                        image: imagen,
                        name: prdData.NombreProducto,
                        sku: prdData.SKU,
                        price: prdData.PrecioProducto,
                        current_stock:999999,
                        category: prdData.catJSON,
                        cooking_options: prdData.OpcionesPreparacion || [],
                        measure_unit: prdData.UnidadMedida,
                        unit_multiplier:prdData.MultiplicadorUnidad,
                    }
                });

                if(firestoreProductCatalog.length > 0) {

                    /* console.log("firestoreProductCatalog", firestoreProductCatalog); */
                    
                    catalog.catalogDispatch({
                        type: 'SET_CATALOG',
                        payload: {
                            "list": firestoreProductCatalog,
                            "viewing": firestoreProductCatalog
                        }
                    });

                    return false;
                }

            }

            const productList = new listProductsAdapter({
                page: 1
            });
        
            const products = productList.output();
            const list = await products.list;
            const viewing = await products.list;
            const SET_CATALOG_PAYLOAD:IProductCatalog = {list,viewing};
    
            catalog.catalogDispatch({
                type: 'SET_CATALOG',
                payload: SET_CATALOG_PAYLOAD
            })

            return false;

        }

        getProducts();

        // Try to fix some cold start problems
        /* try {

            for(let i=0;i<=10;i++){
                axios.get(process.env.REACT_APP_GATEWAYAPI_URL||'');
                axios.get(process.env.REACT_APP_API_URL||'');
            }

        } catch(e) {
            console.log("errors from cold starting... ommit...");
        } */


        return () => {}

    },[]);


    useEffect(()=>{

        let categories:Array<IProductCategory> = catalog.catalog.list.map(product => {
            return {
                category_slug: product.category.category_slug,
                name: product.category.name
            }
        });

        categories = categories.filter((v,i,a)=>a.findIndex(t=>(t.category_slug === v.category_slug))===i);
        setCategories(categories);

    },[catalog.catalog.list])

    useEffect(() => {
        setResponsiveMenuVisibilitiClass('');
        window.scrollTo(0, 0);
    }, [pathname]);


    const logo:IImage = {
        alt:"Susaron",
        height: 110,
        width: 180,
        src:"/static/logo-susaron-borde.png",
    }

    const carrito:IImage = {
        alt:"Susaron",
        height: 31,
        width: 25,
        src:"/static/carrito.svg",
    }

    const perfil:IImage = {
        alt:userFullName,
        height: 31,
        width: 31,
        src:profilePhoto,
    }

    const menu:IImage = {
        alt:"Carnes Susaron",
        height: 50,
        width: 50,
        src:"/static/menu-50-red.png",
    }

    const niceMenuCategoryName = (categoryName:string) => {
        // lowercase categoryName and after capitalize first letter
        return categoryName.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
    }

    const getMenuCategoryList = (categories:Array<any>) => categories.map((category:IProductCategory, index:number) => {

        return {
            text: niceMenuCategoryName(category.name),
            to: `/productos/${category.category_slug}`
        }
    });

    const menuCategoryList = getMenuCategoryList(categories);

    return <>


        <div className="page-header">

            <>
            {/* <div className="header-black-start">
                <p>Despacho en 48hrs</p>
                <IImageComponent {...auto} />
            </div> */}

            <header>
                
                <div className="desktop-header">

                    <div className="brand-image">
                        <Link to="/">
                            <IImageComponent {...logo} />
                        </Link>
                    </div>

                    {categories.length === 0 && <HeaderSkeleton />}
                    
                    {categories.length > 0 &&
                    <div className="desktop-menu">
                        
                        <ul className="menu-list">
                            {categories.map((cat, key) => <li key={key}>
                                <Link to={`/productos/${cat.category_slug}/`}>{cat.name}</Link>
                            </li>)}
                        </ul>

                        <ul className="header-search-and-buttons">
                            
                            <li>
                                <input className="search-box" type="text" />
                                <SearchBox />
                            </li>
                            
                            <li>
                                <Link className='cart-icon-link' to="/cart">
                                    <IImageComponent {...carrito} />
                                    <span className='basket-icon-badge'>
                                        {shoppingCart.shoppingCart.totalProducts}
                                    </span>
                                </Link>
                            </li>
                            
                            <li>
                                <Link to="/account" className='circular--square'>
                                    <IImageComponent {...perfil} />
                                </Link>
                            </li>
                        </ul>
                        

                    </div>}
                    
                </div>
            
            
                <div className="responsive-header">

                    <div className="brand-image">
                        <Link to="/">
                            <IImageComponent {...logo} />
                        </Link>
                    </div>

                    <div className='responsive-nav'>
                        <ul className='menu-list'>

                            <li>
                                <Link className='cart-icon-link' to="/cart">
                                    <IImageComponent {...carrito} />
                                    <span className='basket-icon-badge'>
                                        {shoppingCart.shoppingCart.totalProducts}
                                    </span>
                                </Link>
                            </li>

                            <li className='menu-icon-spacer'>
                                &nbsp;
                            </li>

                            <li className='menu-icon' onClick={e=>setResponsiveMenuVisibility(e)}>
                                <IImageComponent {...menu} />
                            </li>

                            
                            
                            {/* <li>
                                <Link to="/account">
                                    <IImageComponent {...perfil} />
                                </Link>
                            </li> */}
                            
                        </ul>

                        <nav id="responsive-nav-menu" className={responsiveMenuVisibilitiClass}>

                            <MenuMobile menuItems={menuCategoryList} />

                            {/* <ul>

                                <li>
                                    <Link onClick={e=>setResponsiveMenuVisibility(e)} to="/">
                                        Inicio
                                    </Link>
                                </li>

                                <li>
                                    <Link onClick={e=>setResponsiveMenuVisibility(e)} to="/account">
                                        Mi Perfil
                                    </Link>
                                </li>

                                <li>
                                    <Link onClick={e=>setResponsiveMenuVisibility(e)} to="/account/orders/">
                                        Mis Pedidos
                                    </Link>
                                </li>
                                
                                <li>
                                    <h3>Categorías</h3>    
                                </li>

                                {categories.map((cat, key) => 

                                    <li className='category-link' key={key}>
                                        <Link onClick={e=>setResponsiveMenuVisibility(e)} to={`/productos/${cat.category_slug}/`}>
                                            &middot; {niceMenuCategoryName(cat.name)}
                                        </Link>
                                    </li>

                                )}

                                <li>
                                    <Link onClick={e=>setResponsiveMenuVisibility(e)} to="/account/logout/">
                                        Cerrar sesión
                                    </Link>
                                </li>

                            </ul> */}
                        </nav>

                    </div>

                </div>

            </header>
            
            </>

        </div>


        

    </>

}

export default Header