import { IShoppingCart } from "../../domain/contract/ShoppingCart";
import { ACShoppingCartRepository } from "../../domain/repository/shoppinCart";
export class StorageRepository implements ACShoppingCartRepository {

    save(shoppinCart:IShoppingCart, shoppingCartKey:string):void{
        localStorage.setItem(shoppingCartKey, JSON.stringify(shoppinCart));
    }

    recover(shoppingCartKey:string):IShoppingCart {

        const storageData:string|null = localStorage.getItem(shoppingCartKey);
        
        if(!storageData){
            return {
                products:[],
                totalPrice:0,
                totalProducts:0
            }
        }

        const storedShoppingCart = JSON.parse(storageData);

        function isShoppingCart(object: any): object is IShoppingCart {
            return object.hasOwnProperty('products')
        }

        if(!isShoppingCart(storedShoppingCart)) {
            return {
                products:[],
                totalPrice:0,
                totalProducts:0
            }
        }

        return storedShoppingCart as IShoppingCart;

    }

}