import React, {FC} from 'react';
import PasswordResetComponent from '../organisms/PasswordResetComponent';

const PasswordResetPage:FC<any> = () => {
    
    return (<div className="content-wrapper"> 

        <div className="auth-container">
            <h2 className="auth-login-message">Restablecer password</h2>
            <p className="auth-login-message">Por favor ingresa la nueva contraseña para tu cuenta.</p>
            <PasswordResetComponent />
        </div>

    </div>);
}

export default PasswordResetPage;