import ProductAPIContract from "../../domain/services/ProductAPIContract";

export default class ProductAPI extends ProductAPIContract {

    constructor(protected API_BASE_URL:string){
        super(API_BASE_URL);
    }

    get(query:string, headers:object):Promise<any>{

        return this.instance.get(`productos?${query}`, headers);
    }

}