import React, {FC} from "react";

interface IIcon {
    type: string
}

const IconComponent:FC<IIcon> = (props:IIcon) => {

    const {type} = props;
    let icon = '';
    
    switch(type){
        
        case 'search':
            icon='';
            break;
        
        case 'user':
            icon='';
            break;
        
        case 'chevron-left':
            icon='';
            break;
        
        case 'chevron-down':
            icon='';
            break;

        case 'basket':
            icon='';
            break;

    }

    return (<>{icon}</>);
}

export default IconComponent