import React, {FC} from "react";

export interface IInput {
    fieldValue:any,
    onChangeFunc(value:any):void,
    onBlurFunc?(value:any):void,
    inputType?:string
}

const InputComponent:FC<IInput> = (props:IInput) => {

    const obFunc:any = props.onBlurFunc || function(){};
    let inputType = 'text';
    if(props.inputType){
        inputType = props.inputType;
    }

    return (<input type={inputType} value={props.fieldValue} onBlur={e=> obFunc(e)} onChange={e=>props.onChangeFunc(e.target.value)} />)
}

export default InputComponent;