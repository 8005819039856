import ACProductRepository from "../../domain/repository/ProductRepository";
import { IImage } from "../../../Common/infrastructure/components/atoms/Image";
import { IProduct } from '../../domain/dto/ProductDTO';

class APIProductRepository extends ACProductRepository {

    async list(page:number):Promise<Array<IProduct>> {
        const products:Array<any> = await this.api.get('',{});
        return products.map(product => {
 
            let productImage:string = process.env.REACT_APP_DEFAULT_PRODUCT_IMAGE || '';

            if(product.Imagen != null || product?.Imagen?.url === true) {
                productImage = product.Imagen.url;
            }

            let imageProps:IImage = {
                alt: product.name,
                height:200,
                width:200,
                src: productImage
            }

            return {
                id: product.id,
                name: product.NombreProducto,
                price: product.PrecioProducto,
                measure_unit: product.UnidadMedida,
                image: imageProps,
                sku: product.SKU,
                current_stock: product.Stock,
                category: product.CategoriaJSON,
                cooking_options: product.OpcionesDePreparacion,
                unit_multiplier: product.MultiplicadorUnidad
            }
        });
    }

    detail(product_id:number):IProduct {
        let imageProps:IImage = {
            alt:"",
            height:150,
            width:150,
            src:""
        }

        return { id:0, name:"",sku:"", image:imageProps, price:1, measure_unit: 'kg',current_stock:1, category:{category_slug:'',name:''}, cooking_options:[], unit_multiplier:1 };
    }

}

export default APIProductRepository;