import React, {FC} from "react";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const OrdersSkeletonComponent:FC = (props:any) => {

    const rowsStyles = {
        "border": "0"
    }
    
    return (<>

        <div className="account-container skeleton">
            <h1>Tus pedidos anteriores</h1>
                <ul className="order-list">
                    <li style={rowsStyles}>
                        <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                    </li>
                    <li style={rowsStyles}>
                        <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                    </li>
                    <li style={rowsStyles}>
                        <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                    </li>
                    <li style={rowsStyles}>
                        <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                    </li>
                    <li style={rowsStyles}>
                        <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                    </li>
                    <li style={rowsStyles}>
                        <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                    </li>
                    <li style={rowsStyles}>
                        <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                    </li>
                </ul>
        </div>

    </>)

}

export default OrdersSkeletonComponent;