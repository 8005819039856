import ImageGallery from 'react-image-gallery'

export interface ISliderComponentImage {
  original:string,
  thumbnail:string,
  title:string,
  content:string,
  link?:string,
  srcSet?:string|undefined
  sizes?:string|undefined
  alt?:string|undefined
}

export interface ISliderComponentImageList {
  imageList:Array<ISliderComponentImage>;
  onSlide:Function;
}


const SlidersComponent = (props:ISliderComponentImageList) => {

    /* const images = [
        {
          original: 'https://picsum.photos/id/1018/1920/810/',
          thumbnail: 'https://picsum.photos/id/1018/250/150/',
        },
        {
          original: 'https://picsum.photos/id/1015/1920/810/',
          thumbnail: 'https://picsum.photos/id/1015/250/150/',
        },
        {
          original: 'https://picsum.photos/id/1019/1920/810/',
          thumbnail: 'https://picsum.photos/id/1019/250/150/',
        },
    ]; */
    
    return <ImageGallery onSlide={index => {props.onSlide(index)}} showPlayButton={false} showBullets={true} autoPlay={true} showFullscreenButton={false} showThumbnails={false} showNav={false} items={props.imageList} />

}

export default SlidersComponent;