import useCaseAdapter from "../../../Common/domain/useCases/useCaseAdapter";
import getSavedCartUseCase from "../useCases/getSavedCart";
import { StorageRepository } from "../../infraestructure/repository/StorageRepository";
import { IShoppingCart } from "../../domain/contract/ShoppingCart";

export class getSavedCartAdapter implements useCaseAdapter {

    private useCase:getSavedCartUseCase;

    constructor(){
        this.useCase = new getSavedCartUseCase(new StorageRepository());
        this.useCase.execute();
    }

    output():IShoppingCart{
        return this.useCase.result() as IShoppingCart;
    }

}