import React, {FC, useContext, useState, useEffect} from 'react';
import { StoreContext } from '../../../../../store/AppContent';
import  { Redirect, useHistory } from 'react-router-dom';
import AccountMenu from '../../molecules/AccountMenu';
import ClientPersonalDataComponent from '../../organisms/ClientPersonalData';
import { onAuthStateChange } from '../../../providers/auth/firebase/firebase';
import ClientPersonalDataSkeletonComponent from '../../organisms/ClientPersonalDataSkeleton';
/* import { viewUserInformationUseCase } from '../../../../../Clients/application/useCases/viewUserInformation'; */
import { getUserId } from '../../../../../Clients/application/useCases/getUserIdUseCase';
import {auth as firebauth} from '../../../providers/auth/firebase/firebase';

const UserDetailPage:FC<any> = () => {

    localStorage.setItem('currentPage','userdetail');

    const history = useHistory();
    const {auth} = useContext(StoreContext);
    const [isCompletingSignUp, setIsCompletingSignUp] = useState<string|null>('1');
    const [completingSignUpContainerClass, setCompletingSignUpContainerClass] = useState<string>('')
    const [isLoadingUserData, setIsLoadingUserData] = useState(true);
    const [userIsLoggedInAndReady, setUserIsLoggedInAndReady] = useState(false);
    const [userIsCreated, setUserIsCreated] = useState(false);

    const menuOpts = {
        currentPage: 'orders'
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChange(setUserIsLoggedInAndReady);
        const isCompletingSignUpLocalStorareFlag:string|null = localStorage.getItem('isCompletingSignUp');
        setIsCompletingSignUp(isCompletingSignUpLocalStorareFlag);
        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(()=>{

        if(userIsLoggedInAndReady && isCompletingSignUp === '0'){
            setCompletingSignUpContainerClass('');
            setUserIsCreated(true);
        }

        if(userIsLoggedInAndReady && isCompletingSignUp === '1'){
            setCompletingSignUpContainerClass('full');
            setIsLoadingUserData(false);
        }

        const userId = getUserId();
        if((userIsLoggedInAndReady && userIsCreated) && (!userId || firebauth.currentUser?.uid !== userId)) {
            return history.push('/account/logout');
        }

        if(userIsLoggedInAndReady && userIsCreated){
            setIsLoadingUserData(false);
        }

        return ()=>{}

    }, [userIsLoggedInAndReady,userIsCreated, isCompletingSignUp]);

    useEffect(()=>{
        if(isCompletingSignUp === '1'){
            setCompletingSignUpContainerClass('full')
        }
    },[isCompletingSignUp]);

    if(!auth?.user?.accessToken){
        return <Redirect to='/login'  />
    }

    if(isLoadingUserData) {
        return (<>
            <div className={`content-wrapper`}>
        
                {isCompletingSignUp === '0' && <AccountMenu {...menuOpts} />}

                <div className={`account-container ${completingSignUpContainerClass}`}>
                    {isCompletingSignUp === '0' && <h1>Tus datos personales</h1>}
                    <ClientPersonalDataSkeletonComponent />
                </div>

            </div>
        </>)
    }

    return (<div className={`content-wrapper`}>
    
        {isCompletingSignUp === '0' && <AccountMenu {...menuOpts} />}

        <div className={`account-container ${completingSignUpContainerClass}`}>
            {isCompletingSignUp === '0' && <h1>Tus datos personales</h1>}
            {isCompletingSignUp === '1' && <h1>Tus datos personales</h1>}
            <ClientPersonalDataComponent />
        </div>
    
    </div>);
}

export default UserDetailPage