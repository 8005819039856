import useCaseAdapter from "../../../Common/domain/useCases/useCaseAdapter";
import { IShoppingCartProduct } from "../../domain/contract/ShoppingCartProduct";
import { IShoppingCart } from "../../domain/contract/ShoppingCart";
import { StorageRepository } from "../../infraestructure/repository/StorageRepository";
import { incrementProductQtyUseCase } from "../useCases/incrementProductQty";

export class incrementProductQtyAdapter implements useCaseAdapter {

    private useCase:incrementProductQtyUseCase;

    constructor(input:IShoppingCartProduct) {
        this.useCase = new incrementProductQtyUseCase(input, new StorageRepository());
        this.useCase.execute();
    }

    output():IShoppingCart{
        return this.useCase.result();
    }

}