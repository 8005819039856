import IuseCase from "../../../Common/domain/useCases/IuseCase";
import { IShoppingCart } from "../../domain/contract/ShoppingCart";
import { IShoppingCartProduct } from "../../domain/contract/ShoppingCartProduct";
import { ACShoppingCartRepository } from "../../domain/repository/shoppinCart";
import { ShoppingCart } from "../../domain/entity/ShoppingCart";

export class removeProductFromCart implements IuseCase {

    constructor(private productData:IShoppingCartProduct, private cartRepository:ACShoppingCartRepository){
        /* console.log({productData}) */
    }

    execute(){

        const currentShoppingCart = this.cartRepository.recover('shoppingCart');
        const cart = new ShoppingCart(currentShoppingCart);
        cart.removeProduct(this.productData);
        
        const newCart = cart._cart();

        this.cartRepository.save(newCart,'shoppingCart');

        
    }

    result():IShoppingCart{
        return this.cartRepository.recover('shoppingCart');
    }

}