import React, {FC, useState} from 'react';
/* import ContactSkeletonComponent from '../organisms/ContactSkeleton'; */
import { IInput } from '../atoms/Input';
import InputComponent from '../atoms/Input';
import axios from 'axios';
import { getRequestsHeaders } from '../../providers/auth/firebase/firebase';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ContactPage:FC<any> = () => {

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [comuna, setComuna] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitingContact,setIsSubmitingContact] = useState<boolean>(false);
    const [showSuccessMessage,setShowSuccessMessage] = useState<boolean>(false);
    
    const handleClose = () => {
        setShowSuccessMessage(false);
    };

    const nameFieldProps:IInput = {
        fieldValue: name,
        onChangeFunc:setName
    }

    const addressFieldProps:IInput = {
        fieldValue: address,
        onChangeFunc:setAddress
    }

    const comunaFieldProps:IInput = {
        fieldValue: comuna,
        onChangeFunc:setComuna
    }

    const phoneFieldProps:IInput = {
        fieldValue: phone,
        onChangeFunc:setPhone
    }

    const emailFieldProps:IInput = {
        fieldValue: email,
        onChangeFunc:setEmail
    }

    const messageFieldProps:IInput = {
        fieldValue: message,
        onChangeFunc:setMessage
    }
    
    const submitContact = async (e:any) => {
        
        e.preventDefault();
        setIsSubmitingContact(true);
        window.scroll(0,0);

        const headers = getRequestsHeaders();

        return await axios.post(process.env.REACT_APP_CONTACT || '', {
            
            name,
            address,
            comuna,
            phone,
            email,
            message

        }, headers).then(res => {

            console.log(res);
            console.log(res.data);

            // Reset all form fields
            setName('');
            setAddress('');
            setComuna('');
            setPhone('');
            setEmail('');
            setMessage('');

            setIsSubmitingContact(false);
            setShowSuccessMessage(true);

        }).catch(e => {

            console.error(e);
            setIsSubmitingContact(false);
            setShowSuccessMessage(false);
        })

    }

    return (

        <div className={`content-wrapper`}>

            <div className="contact-container full">
                <h1>Contáctanos</h1>
                <div className="contact-input full">
                    <label>Nombre completo</label>
                    <InputComponent {...nameFieldProps} />
                </div>

                <div className="contact-input">
                    <label>Dirección</label>
                    <InputComponent {...addressFieldProps} />
                </div>

                <div className="contact-input">
                    <label>Comuna</label>
                    <InputComponent {...comunaFieldProps} />
                </div>

                <div className="contact-input">
                    <label>Teléfono</label>
                    <InputComponent {...phoneFieldProps} />
                </div>

                <div className="contact-input">
                    <label>Email</label>
                    <InputComponent {...emailFieldProps} />
                </div>

                <div className="contact-input message">
                    <label>Mensaje</label>
                    <InputComponent {...messageFieldProps} />
                </div>

                <div className="buttons">
                    <button onClick={e=>submitContact(e)}>Enviar solicitud de contacto</button>
                </div>

            </div>

            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={isSubmitingContact}
                                onClick={e => () => {}}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
    
    
        <Dialog
            open={showSuccessMessage}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
          {"Gracias"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hemos recibido su mensaje, pronto nos pondremos en contacto con Ud.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>


        </div>);

}

export default ContactPage;