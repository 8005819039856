import useCaseAdapter from "../../../Common/domain/useCases/useCaseAdapter";
import { IShoppingCartProduct } from "../../domain/contract/ShoppingCartProduct";
import { IShoppingCart } from "../../domain/contract/ShoppingCart";
import { StorageRepository } from "../../infraestructure/repository/StorageRepository";
import { removeProductFromCart } from "../useCases/removeProductFromCart";

export class removeProductFromCartAdapter implements useCaseAdapter {

    private useCase:removeProductFromCart;

    constructor(input:IShoppingCartProduct) {
        this.useCase = new removeProductFromCart(input, new StorageRepository());
        this.useCase.execute();
    }

    output():IShoppingCart{
        return this.useCase.result();
    }

}