import React, {FC} from 'react';
import PasswordRecoveryComponent from '../organisms/PasswordRecoveryComponent';

const PasswordRecoveryPage:FC<any> = () => {
    
    return (<div className="content-wrapper"> 

        <div className="auth-container">
            <h2 className="auth-login-message">Recuperar password</h2>
            <p className="auth-login-message">Por favor ingresa tu correo y te enviaremos instrucciones para recuperar tu contraseña.</p>
            <PasswordRecoveryComponent />
        </div>

    </div>);
}

export default PasswordRecoveryPage;