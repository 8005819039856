import axios from 'axios';
import React, {FC, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import SlidersComponent from '../organisms/SlidersComponent';
import { ISliderComponentImage } from '../organisms/SlidersComponent';
import SliderSkeleton from '../organisms/SlidersSkeleton';
import { firestore } from '../../providers/auth/firebase/firebase'

const getDeviceOrViewport = ():string => {
    
    /* const height = window.innerHeight; */
    const width = window.innerWidth;
    /* console.log("width", width); */

    if(width <= 768) {
        return 'cellphone';
    }

    if(width <= 1024) {
        return 'tablet';
    }

    if(width >= 1280) {
        return 'desktop';
    }

    return 'desktop';
}


const Home:FC<any> = (props:any) => {

    

    const sliderStyle = {
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url(static/slider.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '420px',
        maxHeight: '420px',
    }

    /** Get the sliders from Strpi API */
    const [sliders, setSliders] = useState<Array<ISliderComponentImage>>([]);
    const [slidersTablet, setSlidersTablet] = useState<Array<ISliderComponentImage>>([]);
    const [slidersCellphone, setSlidersCellphone] = useState<Array<ISliderComponentImage>>([]);
    const [sliderTitle, setSliderTitle] = useState<string>('');
    const [sliderDescription, setSliderDescription] = useState<string>('');
    const [sliderLink, setSliderLink] = useState<string|undefined>('');
    const [clientViewport, setClientViewport] = useState<string>('desktop');
    //const [currentSliderIndex, setCurrentSliderIndex] = useState<number>(0);
    const [showSlidersSkeleton, setShowSlidersSkeleton] = useState<boolean>(true);

    useEffect(() => {

        const apiSliderList = async() => {

            const cachedSliders = await firestore.collection('sliders').get();
            
            const firestoreSliderList:Array<any> = cachedSliders.docs.map(slider => {
                const data = slider.data();
                return {
                    "Bajada_titulo": data.Bajada_titulo,
                    "Contenido": data.Contenido,
                    "Imagen_Table": data.Imagen_Table,
                    "Imagen_celular": data.Imagen_celular,
                    "Imagen_desktop": data.Imagen_desktop,
                    "Titulo": data.Titulo,
                    "id": data.id,
                    "link": data.link,
                }
            });

            let sliderList:Array<any>;

            if(firestoreSliderList.length > 0) {
                sliderList = firestoreSliderList;
            } else {
                const _apiSliderList:Array<any> = await axios.get(`${process.env.REACT_APP_API_URL}/sliders`).then(res => res.data).catch(error => console.error(error));
                sliderList = _apiSliderList;
            }

            const list:Array<ISliderComponentImage> = sliderList.map(slider => {

                if( !slider?.Imagen_desktop || !slider?.Imagen_celular || !slider?.Imagen_Table ||
                    slider.Imagen_desktop == null || slider.Imagen_celular == null || slider.Imagen_Table == null ) {

                    return {
                        original: '',
                        thumbnail: '',
                        title: '',
                        content: '',
                        link: undefined,
                        srcSet: undefined
                    }
                }

                const _link = slider["link"] || undefined;

                return {
                    original: slider["Imagen_desktop"]["url"],
                    thumbnail: slider["Imagen_desktop"]["formats"]["thumbnail"]["url"],
                    title: slider["Titulo"],
                    content: slider["Contenido"],
                    link: _link,
                    alt:slider["Titulo"],
                    originalWidth: 365
                }
                
            });

            const listTablet:Array<ISliderComponentImage> = sliderList.map(slider => {

                if( !slider?.Imagen_desktop || !slider?.Imagen_celular || !slider?.Imagen_Table ||
                    slider.Imagen_desktop == null || slider.Imagen_celular == null || slider.Imagen_Table == null ) {

                    return {
                        original: '',
                        thumbnail: '',
                        title: '',
                        content: '',
                        link: undefined,
                        srcSet: undefined
                    }
                }

                const _link = slider["link"] || undefined;

                return {
                    original: slider["Imagen_Table"]["url"],
                    thumbnail: slider["Imagen_Table"]["formats"]["thumbnail"]["url"],
                    title: slider["Titulo"],
                    content: slider["Contenido"],
                    link: _link,
                    alt:slider["Titulo"],
                    originalWidth: 365
                }
                
            });

            const listCellphone:Array<ISliderComponentImage> = sliderList.map(slider => {

                if( !slider?.Imagen_desktop || !slider?.Imagen_celular || !slider?.Imagen_Table ||
                    slider.Imagen_desktop == null || slider.Imagen_celular == null || slider.Imagen_Table == null ) {
                        
                    return {
                        original: '',
                        thumbnail: '',
                        title: '',
                        content: '',
                        link: undefined,
                        srcSet: undefined
                    }
                }

                const _link = slider["link"] || undefined;

                return {
                    original: slider["Imagen_celular"]["url"],
                    thumbnail: slider["Imagen_celular"]["formats"]["thumbnail"]["url"],
                    title: slider["Titulo"],
                    content: slider["Contenido"],
                    link: _link,
                    alt:slider["Titulo"],
                    originalWidth: 365
                }
                
            });

            
            const listOk:Array<ISliderComponentImage> = list.filter(slider => slider.original !== '');
            const listTabletOk:Array<ISliderComponentImage> = listTablet.filter(slider => slider.original !== '');
            const listCellphoneOk:Array<ISliderComponentImage> = listCellphone.filter(slider => slider.original !== '');

            setSliders(listOk);
            setSlidersTablet(listTabletOk);
            setSlidersCellphone(listCellphoneOk);
            setShowSlidersSkeleton(false);
        }

        apiSliderList();

        /** Check if window resize and set to correct viewport */
        window.addEventListener('resize', function(){
            const device = getDeviceOrViewport();
            setClientViewport(device);
        });

        const device = getDeviceOrViewport();
        setClientViewport(device);

        return () => {}

    },[]);

    useEffect(()=>{

        if(sliders.length > 0) {
            setSliderTitle(sliders[0].title);
            setSliderDescription(sliders[0].content);
            setSliderLink(sliders[0].link);
        }

    },[sliders]);

    const onSlide:Function = (index:number) => {
        
        if(sliders.length > 0) {

            setSliderTitle(sliders[index].title);
            setSliderDescription(sliders[index].content);
            setSliderLink(sliders[index].link);

        }

    }

    return (<>
        <div className="home-content">
            
            {(showSlidersSkeleton || sliders.length === 0) &&  <div style={sliderStyle} className="home-sliders">&nbsp;</div>}
            {(showSlidersSkeleton || sliders.length === 0) &&  <SliderSkeleton />}
            
            {!showSlidersSkeleton && clientViewport === 'desktop' && sliders.length > 0 && <SlidersComponent onSlide={onSlide} imageList={sliders} />}
            {!showSlidersSkeleton && clientViewport === 'tablet' && slidersTablet.length > 0 && <SlidersComponent onSlide={onSlide} imageList={slidersTablet} />}
            {!showSlidersSkeleton && clientViewport === 'cellphone' && slidersCellphone.length > 0 && <SlidersComponent onSlide={onSlide} imageList={slidersCellphone} />}

            {!showSlidersSkeleton && sliders.length > 0 && 
            <div className="home-options">
                <div className="home-options-content">
                    <h1>{sliderTitle}</h1>
                    <ul className="home-sliders-menu">
                        {sliderLink === undefined && <>
                            <li>
                                <Link className="button home" to="/productos/vacuno">
                                    Ver productos
                                </Link>
                                </li>
                            <li>
                                <Link className="button home reverse" to="/account/orders/">
                                    Repetir pedido
                                </Link>
                            </li>
                        </>}
                        {sliderLink !== undefined && sliderLink.length >= 1 && <>

                            <li>
                                <Link className="button home reverse" to={sliderLink}>
                                    {sliderDescription &&<>{sliderDescription}</>}
                                    {!sliderDescription &&<>Click para ver más</>}
                                </Link>
                            </li>

                        </>}

                    </ul>
                </div>
            </div>}

        </div>

        {/* <div id="home-sliders">
            <SlidersComponent {...HomeSliders} />
        </div> */}

    </>);
}

export default Home;