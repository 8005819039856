import React, {FC, useEffect, useState} from 'react';
import InputComponent from '../atoms/Input';
import { IInput } from '../atoms/Input';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const qs = require('query-string');

const __setNewAccountPassword = async (pass1:string, confPass:string) => {

    if(pass1.trim() === '' || confPass.trim() === ''){
        alert("Las passwords son obligatorias")
        return false;
    }

    if(pass1 !== confPass){
        alert("Las passwords no coinciden")
        return false;
    }

    const url = process.env.REACT_APP_RESET_USER_PASSWORD || '';
    if(!url){
        console.error("No se ha definido la url para resetear la password")
        return false;
    }

    // http://localhost:3000/password-reset?code=5056f590-b073-4e11-b934-faa2a398beef&email=fabian@gomd.cl&fb_code=%3COOBCODE%3E&token=%3CAPIKEY%3E

    const queryparams:any = qs.parse(window.location.search);
    const payload = {
        "code" : queryparams.code,
        "email" : queryparams.email, 
        "password" : pass1, 
        "password2" : confPass, 
        "token" : queryparams.token, 
        "fb_code" : queryparams.fb_code
    }

    const result = axios.post(url,payload);
    return result;

}

const PasswordResetComponent:FC<any> = (props:any) => {

    const [pass1, setPass1] = useState<string>('');
    const [pass2, setPass2] = useState<string>('');
    const [submiting, setSubmiting] = useState<boolean>(false);
    const { push } = useHistory();
    
    const resetPassword = async (e:any) => {

        try {
            
            setSubmiting(true);
            const passwordChangeResult = await __setNewAccountPassword(pass1, pass2);

            if(!passwordChangeResult){
                console.error("passwordChangeResult", passwordChangeResult);
                setSubmiting(false);
                alert("Error al resetear la password");
                return false;
            }

            setSubmiting(false);
            return true;

        } catch(e) {

            alert("Error al resetear la password");
            setSubmiting(false);
            return false;
        }

    }

    const pass1FieldProps:IInput = {
        fieldValue: pass1,
        onChangeFunc:setPass1,
        inputType: 'password'
    }

    const pass2FieldProps:IInput = {
        fieldValue: pass2,
        onChangeFunc:setPass2,
        inputType: 'password'
    }

    if(submiting) {
        return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={submiting}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
        )
    }
    
    return (<>

        <div id="login-options-container">

            <div id="custom-login-form">

                <div className='input-login'>
                    <label>Nueva password</label>
                    <InputComponent {...pass1FieldProps} />
                </div>

                <div className='input-login'>
                    <label>Confirmar nueva password</label>
                    <InputComponent {...pass2FieldProps} />
                </div>

                <button className='btn-login' onClick={e=> {
                    setSubmiting(true);
                    resetPassword(e).then(res => {
                        
                        setSubmiting(false);
                        
                        if(res) {
                            push('/login');
                        }
                        
                    }).catch(err => {
                        console.log("err", err);
                    })
                }}>
                    Cambiar contraseña
                </button>

                {<Link to="/login">
                    <button className='btn-forgotten-password'>
                        Volver
                    </button>
                </Link>}

            </div>

            
        </div>

    </>)
}

export default PasswordResetComponent;