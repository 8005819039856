import React, {FC, useContext, useEffect, useState} from 'react';
import { ADD_TO_CART_ACTION, StoreContext } from '../../../../../store/AppContent';
import  { Link, Redirect } from 'react-router-dom'
import AccountMenu from '../../molecules/AccountMenu';
import { IShoppingCartProduct } from '../../../../../Cart/domain/contract/ShoppingCartProduct';
import { addToCartAdapter } from '../../../../../Cart/application/adapters/addToCartAdapter';
import { useHistory } from "react-router-dom";
import { ViewOrderDetailUseCase } from '../../../../../Orders/application/useCases/viewOrderDetail';
import { onAuthStateChange } from '../../../providers/auth/firebase/firebase';
import LoaderComponent from '../../molecules/Loader';
import NumberFormat from 'react-number-format';
import OrderDetailSkeletonComponent from '../../organisms/OrderDetailSkeleton';

const capitalizeString = (_string:string) => {
    if(!_string) {
        return _string;
    }
    return _string.charAt(0).toUpperCase() + _string.slice(1);
}

const OrderDetailPage:FC<any> = (props:any) => {

    localStorage.setItem('currentPage','pastorders');

    const {auth} = useContext(StoreContext);
    const [userIsLoggedInAndReady, setUserIsLoggedInAndReady] = useState(false);
    const history = useHistory();
    const { shoppingCart } = useContext(StoreContext);
    const [orderDetail, setOrderDetail] = useState<any>({})
    const [orderId, setOrderId] = useState<number>(0)
    const [orderProducts, setOrderProducts] = useState<Array<IShoppingCartProduct>>([]);
    const [isLoadingUserData, setIsLoadingUserData] = useState(true);

    const menuOpts = {
        currentPage: 'orders'
    }

    useEffect(() => {
        
        const unsubscribe = onAuthStateChange(setUserIsLoggedInAndReady);
        
        return () => {
            unsubscribe();
        };

    }, []);

    useEffect(()=>{

        const getOrderDetail = async () => {

            let order:any = await ViewOrderDetailUseCase(orderId);
            order.created_at = capitalizeString(String(new Date(order.created_at).toLocaleDateString('es-CL', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })));
            setOrderDetail(order)

            const orderProducts = order["PayloadPedido"] as Array<IShoppingCartProduct>
            setOrderProducts(orderProducts);
            setIsLoadingUserData(false);
            
        }

        if(!orderId || orderId === 0) {
            return ()=>{}    
        }

        if(userIsLoggedInAndReady && orderId > 0){
            getOrderDetail();
        }

        return ()=>{}

    }, [orderId, userIsLoggedInAndReady]);

    
    useEffect(()=>{

        setOrderId(props.match.params.id);
        return () => {}
        
    },[props.match.params.id])


    
    const duplicateOrder = (orderData:Array<IShoppingCartProduct>) => {

        orderData.map((prd)=> {
            
            prd.qty = Math.abs(prd.qty)
            prd.id = Math.abs(prd.id)

            let _addToCartAdapter = new addToCartAdapter(prd);
            let addToCartResult = _addToCartAdapter.output();
    
            return shoppingCart.shoppingCartDispatch({
                type: ADD_TO_CART_ACTION,
                payload: addToCartResult,
            });

        });

        history.push(`/checkout/`);

    }
    

    if(auth.user.accessToken === null){
        return <Redirect to='/login'  />
    }

    if(isLoadingUserData) {
        return (
        <>
        <div className="content-wrapper">
            <AccountMenu {...menuOpts} />
            <OrderDetailSkeletonComponent />
        </div>
        </>
        )
    }

    return (<div className="content-wrapper">
    
        <AccountMenu {...menuOpts} />

        <div className="order-container full order-details">
            <h1>Detalle Orden</h1>

            <dl id="order-detail-list">
                <dt>N° Orden</dt>
                <dd>{orderDetail.id}</dd>
                <dt>Fecha</dt>
                <dd>{orderDetail.created_at}</dd>
                <dt>Ciudad destino</dt>
                <dd>{capitalizeString(orderDetail.Ciudad)}</dd>
                <dt>Comuna destino</dt>
                <dd>{capitalizeString(String(orderDetail.ComunaEntrega).replace('-', ' '))}</dd>
                <dt>Dirección entrega</dt>
                <dd>{capitalizeString(orderDetail.DireccionEntrega)} {capitalizeString(orderDetail.NumeroCalle)} {capitalizeString(orderDetail.VillaBlockDepto)}</dd>
                <dt>Estado de la orden</dt>
                <dd>{capitalizeString(String(orderDetail.EstadoOrden).toLowerCase())}</dd>
                <dt>Total aprox.</dt>
                <dd><NumberFormat decimalPrecision={0} decimalSeparator=',' displayType="text" value={orderDetail.TotalAprox} thousandSeparator="." prefix={'$'} /></dd>
                <dt>Total despacho</dt>
                <dd><NumberFormat decimalPrecision={0} decimalSeparator=',' displayType="text" value={orderDetail.TotalDespacho||0} thousandSeparator="." prefix={'$'} /></dd>
                {orderDetail.TotalFinal !== undefined && orderDetail.TotalFinal !== null && typeof orderDetail.TotalFinal === 'number' && orderDetail.TotalFinal > 0 && <>
                <dt>Total pesado</dt>
                <dd><NumberFormat decimalPrecision={0} decimalSeparator=',' displayType="text" value={orderDetail.TotalFinal || 0} thousandSeparator="." prefix={'$'} /></dd>
                <dt>Total final</dt>
                <dd><NumberFormat decimalPrecision={0} decimalSeparator=',' displayType="text" value={orderDetail.TotalDespacho||0 + orderDetail.TotalFinal||0} thousandSeparator="." prefix={'$'} /></dd>
                </>}
            </dl>
            
            {orderProducts && orderProducts.length > 0 && 
            <table cellSpacing={0} id="order-detail-product-list">
                <thead>
                    <tr>
                        {/* <th>SKU</th> */}
                        <th>Producto</th>
                        <th>Cantidad</th>
                    </tr>
                </thead>
                <tbody>
                    {orderProducts.map((product,key)=>
                    
                    <tr key={key}>
                        {/* <td>{product.sku}</td> */}
                        <td>{product.name}</td>
                        <td>{product.qty}</td>
                    </tr>

                    )}
                </tbody>
            </table>}

            <div className="spacer h30">&nbsp;</div>

            <div className="buttons">
                
                {orderProducts && orderProducts.length > 0 && 
                <button onClick={e=>duplicateOrder(orderProducts)}>
                    Repetir pedido
                </button>}

                <Link to="/account/orders">
                    <button className='reverse'>
                        Volver
                    </button>
                </Link>

            </div>

        </div>
    
    </div>);

}

export default OrderDetailPage