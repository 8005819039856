import React, {FC, useContext, useEffect, useState} from 'react';
import { StoreContext } from '../../../../../store/AppContent';
import  { Redirect } from 'react-router-dom'
import AccountMenu from '../../molecules/AccountMenu';
import { useHistory } from "react-router-dom";
import { ListClientOrdersUseCase } from '../../../../../Orders/application/useCases/listClientOrders';
import { onAuthStateChange } from '../../../providers/auth/firebase/firebase';
/* import LoaderComponent from '../../molecules/Loader'; */
import OrdersSkeletonComponent from '../../organisms/OrdersSkeleton';
import Alert from '@mui/material/Alert';
import NumberFormat from 'react-number-format';

/* import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup'; */
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';




const OrdersPage:FC<any> = () => {

    localStorage.setItem('currentPage','pastorders');

    const history = useHistory();
    const {auth} = useContext(StoreContext);
    const [userIsLoggedInAndReady, setUserIsLoggedInAndReady] = useState(false);
    const [isLoadingUserData, setIsLoadingUserData] = useState(true);
    const [pastOrders, setPastOrders] = useState([]);

    const menuOpts = {
        currentPage: 'orders'
    }

    const goToOrderDetail = (id:number) => {
        history.push(`/account/orders/${id}/`);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChange(setUserIsLoggedInAndReady);
        return () => {
            unsubscribe();
        };

    }, []);

    useEffect(()=>{

        const getOrders = async () => {

            const orders = await ListClientOrdersUseCase();
            console.log("orders", orders);
            setPastOrders(orders);
            setIsLoadingUserData(false);
            
        }

        if(userIsLoggedInAndReady){
            getOrders();
        }
        
        return ()=>{}

    }, [userIsLoggedInAndReady]);

    if(auth.user.accessToken === null){
        return <Redirect to='/login'  />
    }

    if(isLoadingUserData){
        
        return(<div className="content-wrapper">
            <AccountMenu {...menuOpts} />
            <OrdersSkeletonComponent />
        </div>);
    }

    const ordersTitleStyle = {
        width: '100%',
        marginBottom: '15px',
    }

    const niceDate = (date:string) => {

        try {
            const _date = new Date(date);
            // return `${_date.getDate()}/${_date.getMonth()+1}/${_date.getFullYear()}`;

            return _date.toLocaleString('es-CL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute:'2-digit' })

            /* capitalizeString(String();
            setOrderDetail(order) */

        } catch(e) {
            return date;
        }

        
    }

    return (<div className="content-wrapper">
    
        <AccountMenu {...menuOpts} />

        <div className="account-container">
            <h1 style={ordersTitleStyle}>Historial de pedidos</h1>
            {pastOrders && pastOrders.length === 0 && 
                <Alert variant="outlined" severity="info" sx={{ borderColor: "#ddd" }}>
                    Aún no haz realizado ningún pedido.
                </Alert>
            }

            {pastOrders && pastOrders.map((order:any, key:number) => {
                return <div key={`order-${key}-wrapper`} style={{width:"100%"}}>

                    <div key={`order-${key}`} className="past-orders-item" style={{"width": "100%"}}>
                        <h4><CalendarMonthOutlinedIcon /> &nbsp;{niceDate(order["created_at"])}</h4>
                        <div className="detail">
                           
                            <ul className="product-list">
                                <li style={{display:"flex", flexWrap:"wrap"}} key={`order-product-list-detail-${key}`}>
                                    Productos solicitados: <br/>
                                    <div className="spacer">&nbsp;</div>
                                    {order?.PayloadPedido && order?.PayloadPedido.map((prd:any,idx:number) =>  <span key={`prd-${idx}`} className="prd-list-item">{prd.name} x {prd.qty}</span>)}
                                </li>
                                <li>
                                    <b>Total</b>: <NumberFormat decimalPrecision={0} decimalSeparator=',' displayType="text" value={order["TotalAprox"]} thousandSeparator="." prefix={'$'} />
                                </li>
                            </ul>
                        </div>
                        <div key={`buttons-order-${key}`} className="buttons">
                            <Stack direction="column" spacing={1}>
                                {/* <IconButton aria-label="Repetir pedido">
                                    <ContentCopyOutlinedIcon />
                                </IconButton> */}
                                <IconButton aria-label="Ver detalle" onClick={e => goToOrderDetail(order?.id)} >
                                    <SearchOutlinedIcon />
                                </IconButton>
                            </Stack>
                        </div>
                    </div>
                
                </div>
            })}

        </div>
    
    </div>);

}

export default OrdersPage