import React, {FC} from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const SliderSkeleton:FC<any> = () => {
    return (
        <div className="home-options">
            <div className="home-options-content">
                <h1>
                    <Stack spacing={1}>
                        <Skeleton sx={{ bgcolor: 'grey.500' }} height={30} variant="text" />
                        <Skeleton sx={{ bgcolor: 'grey.500' }} height={30} variant="text" />
                        <Skeleton sx={{ bgcolor: 'grey.500' }} height={30} variant="text" />
                    </Stack>
                </h1>
                <ul className="home-sliders-menu" style={{"marginTop": "0"}}>
                    <li>
                        <Skeleton sx={{ bgcolor: 'grey.500' }} width={190} height={90} variant="text" />
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default SliderSkeleton;