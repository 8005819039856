import React, {FC} from "react";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const CheckoutSkeletonComponent:FC = (props:any) => {
    return (
        <div className="content-wrapper">

            <div className="checkout-container">
                <h1>Finalizar pedido</h1>

                <div className="spacer h30">&nbsp;</div>

                <div>
                    <h2 className='form-title'>Datos de contacto</h2>
                </div>

                <div className="spacer h30">&nbsp;</div>

                <div className="checkout-input">
                    <label>Persona de contacto</label>
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                </div>

                <div className="checkout-input">
                    <label>Celular de contacto</label>
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                </div>


                <div className="spacer h30">&nbsp;</div>

                <div>
                    <h2 className='form-title'>Despacho</h2>
                </div>

                <div className="spacer h30">&nbsp;</div>

                <div className="checkout-input last-address-select">
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                </div>


                <div className="checkout-input">
                    <label>Comuna</label>
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                </div>

                <div className="checkout-input">
                    <label>Calle</label>
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                </div>

                <div className="checkout-input">
                    <label>Número calle</label>
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                </div>

                <div className="checkout-input">
                    <label>Depto/Block/Villa</label>
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                </div>

                <div className="checkout-input references">
                    <label>Referencias dirección</label>
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                </div>

                <div className="spacer h30">&nbsp;</div>

                {/* {showAddressMap && !addressMapConfirmed &&
                <div style={{width: '100%'}}>

                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={250} variant="text" />

                    <div id="checkout-confirm-adress-buttons">
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                    </div>

                </div>} */}

                
                
            </div>

            <div className="checkout-container checkout-cart-container">

                <div className="checkout-product-list">
                    {/* <SideCart {...{deliveryCost}} /> */}
                </div>

                <div className='pago-title'>
                    <h2 className='form-title'>Método de pago</h2>
                </div>

                <div className="checkbox">
                    <Stack spacing={1}>
                        <Skeleton sx={{ bgcolor: 'grey.500' }} width="100%" height={20} variant="text" />
                        <Skeleton sx={{ bgcolor: 'grey.500' }} width="100%" height={20} variant="text" />
                    </Stack>

                </div>

                <div className="checkout-buttons">
                    <Skeleton sx={{ bgcolor: 'grey.500' }} width="100%" height={70} variant="text" />
                </div>

            </div>

        </div>
    )
}

export default CheckoutSkeletonComponent;