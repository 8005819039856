import React, {FC} from 'react'
/* import InputComponent from '../atoms/Input'
import IconComponent from '../atoms/Icon' */
import {Link} from 'react-router-dom';

const AccountMenu:FC<any> = (props:any) => {

    const {currentPage} = props;
    
    const menu = [
        {
            text: 'Mis datos personales',
            link: '/account/'
        },
        {
            text: 'Mis órdenes',
            link: '/account/orders/'
        },
        {
            text: 'Cerrar sesión',
            link: '/account/logout/'
        }
    ]

    return <div className="account-menu">
        
        <ul>
            {menu.map((men, key) => 
            <li key={key}>
                <Link to={men.link}>{men.text}</Link>
            </li>
            )}
        </ul>

    </div>
}

export default AccountMenu