import React, {FC} from 'react'
import ProductBox from '../molecules/ProductBox';
import { IProductCatalog } from '../../../domain/contracts/IProductCatalog';

const ProductCatalog:FC<IProductCatalog> = (products:IProductCatalog) => {
    
    return (<div className="product-catalog">
    
        {products.viewing.length === 0 && <div>Cargando productos</div>}
        {products.viewing.length > 0 &&  products.viewing.map((product,index) => <ProductBox key={index} {...product} />)}
    
    </div>)
}

export default ProductCatalog