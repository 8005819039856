import React, {FC} from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const HeaderSkeleton:FC<any> = () => {

    return (
        <div className="desktop-menu">
            <ul className="menu-list">
                <li style={{"width": "10%"}}>
                    <Stack spacing={1}>
                        <Skeleton variant="text" />
                    </Stack>
                </li>
                <li style={{"width": "10%"}}>
                    <Stack spacing={1}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Stack>
                </li>
                <li style={{"width": "10%"}}>
                    <Stack spacing={1}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Stack>
                </li>
                <li style={{"width": "10%"}}>
                    <Stack spacing={1}>
                        <Skeleton variant="text" />
                    </Stack>
                </li>
                <li style={{"width": "10%"}}>
                    <Stack spacing={1}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Stack>
                </li>
                <li style={{"width": "10%"}}>
                    <Stack spacing={1}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Stack>
                </li>
                <li style={{"width": "10%"}}>
                    <Stack spacing={1}>
                        <Skeleton variant="text" />
                    </Stack>
                </li>
                <li style={{"width": "10%"}}>
                    <Stack spacing={1}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Stack>
                </li>
                <li style={{"width": "60px"}}>
                    <Stack spacing={1}>
                        <Skeleton variant="circular" width={40} height={40} />
                    </Stack>
                </li>
                <li style={{"width": "60px"}}>
                    <Stack spacing={1}>
                        <Skeleton variant="circular" width={40} height={40} />
                    </Stack>
                </li>
            </ul>
        </div>
    )

}

export default HeaderSkeleton;
