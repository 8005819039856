import React, {FC, useContext, useState, useEffect} from 'react';
import SideCart from '../../../../Cart/infraestructure/components/molecules/SideCart';
import InputComponent, { IInput } from '../atoms/Input';
import { StoreContext } from '../../../../store/AppContent';
import { PlaceOrderAdapter } from '../../../../Orders/application/adapters/placeOrderAdapter';
import { IOrder } from '../../../../Orders/domain/domain/order';
import { viewUserInformationUseCase } from '../../../../Clients/application/useCases/viewUserInformation';
import { getUserId } from '../../../../Clients/application/useCases/getUserIdUseCase';
import { getComunasListUseCase } from '../../../../Delivery/application/useCases/getComunasListUseCase';
import { getDeliveryCostUseCase } from '../../../../Delivery/application/useCases/getDeliveryCostUseCase';
import { onAuthStateChange } from '../../providers/auth/firebase/firebase';
import { Redirect, useHistory } from 'react-router-dom';
import { IShoppingCart } from '../../../../Cart/domain/contract/ShoppingCart';
import CheckoutSkeletonComponent from '../organisms/CheckoutSkeleton';
import SubmitingOrderSkeletonComponent from '../organisms/SubmitingOrderSkeleton';

import { Backdrop } from '@mui/material';
import { CircularProgress } from '@mui/material';


const Checkout:FC<any> = (props:any) => {

    localStorage.setItem('currentPage','checkout');


    const history = useHistory();
    const {auth, shoppingCart} = useContext(StoreContext);
    const [userIsLoggedInAndReady, setUserIsLoggedInAndReady] = useState(false);
    const [userHasAllMandatoryProfileData, setUserHasAllMandatoryProfileData] = useState(false);
    const [isLoadingUserData, setIsLoadingUserData] = useState(true);
    const [isSubmitingOrder, setIsSubmitingOrder] = useState(false);

    const [deliveryContactName, setDeliveryContactName] = useState('');
    const [deliveryContactPhone, setDeliveryContactPhone] = useState('');
    

    const [city, setCity] = useState('Santiago');
    const [regionSubdivition, setRegionSubdivition] = useState('');
    const [address, setAddress] = useState('');
    const [addressNumber, setAddressNumber] = useState('');
    const [addressExtra, setAddressExtra] = useState('');
    const [addressReference, setAddressReference] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('Transbank');


    const [lasAdressFullText, setLasAdressFullText] = useState('');
    // const [lastCity, setLastCity] = useState('Santiago');
    const [lastRegionSubdivition, setLastRegionSubdivition] = useState('');
    const [lastAddress, setLastAddress] = useState('');
    const [lastAddressNumber, setLastAddressNumber] = useState('');
    const [lastAddressExtra, setLastAddressExtra] = useState('');
    const [lastAddressReference, setLastAddressReference] = useState('');

    
    const [addressMap, setaddressMap] = useState('');
    const [canUseOldAddress, setCanUseOldAddress] = useState(true);
    const [isNewAddress, setIsNewAddress] = useState(false);
    const [showAddressMap, setShowAddressMap] = useState(false);
    const [addressMapConfirmed, setAddressMapConfirmed] = useState(false);


    const [comunasList, setComunasList] = useState<Array<any>>([]);
    const [deliveryCost, setDeliveryCost] = useState<number>(0);


    // Post haber generado el pedido
    const [showSuccess, setShowSucces] = useState(false);

    const nameFieldProps:IInput = {
        fieldValue: deliveryContactName,
        onChangeFunc:setDeliveryContactName
    }

    const phoneFieldProps:IInput = {
        fieldValue:deliveryContactPhone,
        onChangeFunc:setDeliveryContactPhone
    }

    const addressFieldProps:IInput = {
        fieldValue:address,
        onChangeFunc:setAddress
    }

    const addressNumberFieldProps:IInput = {
        fieldValue:addressNumber,
        onChangeFunc:setAddressNumber
    }

    const addressExtraFieldProps:IInput = {
        fieldValue:addressExtra,
        onChangeFunc:setAddressExtra
    }

    const addressReferenceFieldProps:IInput = {
        fieldValue:addressReference,
        onChangeFunc:setAddressReference
    }

    const OrderPayload:IOrder = {
        deliveryContactName, 
        deliveryContactPhone,
        address,
        addressNumber,
        addressExtra,
        comuna:regionSubdivition, 
        city, 
        addressReference, 
        paymentMethod,
        items:shoppingCart.shoppingCart.products,
        totalAprox:shoppingCart.shoppingCart.totalPrice
    }

    const submitOrder = async (e:any):Promise<any> => {
        
        try {
            window.scroll(0,0);
            setIsSubmitingOrder(true);
            const adapter = new PlaceOrderAdapter(OrderPayload);
            const result:any = await adapter.output();

            console.log("result", result);
            
            if(result?.status === true && result?.orderId) {
                
                const emptyCartPayload:IShoppingCart = {
                    products: [], totalPrice: 0, totalProducts: 0
                }

                shoppingCart.shoppingCartDispatch({
                    type: 'EMPTY_CART',
                    payload: emptyCartPayload,
                });

                localStorage.removeItem('shoppingCart');

                setIsSubmitingOrder(false);
                setShowSucces(true);
                return history.push(`/account/orders/${result?.orderId}/`);
            } 

            else {

                setIsSubmitingOrder(false);
                setShowSucces(false);
                alert("Ocurrió un error al ingresar su pedido, por favor intente más tarde");
            }

        } catch(e) {

            setIsSubmitingOrder(false);
            setShowSucces(false);
            alert("Ocurrió un error al ingresar su pedido, por favor intente más tarde");

        }

    }

    useEffect(() => {
        const unsubscribe = onAuthStateChange(setUserIsLoggedInAndReady);
        return () => {
        unsubscribe();
        };
    }, []);

    useEffect(()=>{
        if(isSubmitingOrder){
            window.scrollTo(0,0);
        }
    },[isSubmitingOrder])

    useEffect(()=>{

        const getUsrInformation = async () => {

            const userId = getUserId();

            if(!userId) {
                return false;
            }

            const info = await viewUserInformationUseCase(userId);
            const comunasList = await getComunasListUseCase();

            const {Nombre, Apellido, Telefono, UltimaCiudadUtilizada, UltimaComunaUtilizada, UltimaDireccionUtilizada, UltimoNumeroDireccionUtilizada, UltimaReferenciaDireccionUtilizada, UltimoInfoExtraDireccionUtilizada, DatosCompletos} = info;


            setDeliveryContactName(`${Nombre} ${Apellido}`);
            setDeliveryContactPhone(Telefono);
            setRegionSubdivition(UltimaComunaUtilizada);
            setAddress(UltimaDireccionUtilizada);
            setAddressNumber(UltimoNumeroDireccionUtilizada);
            setAddressExtra(UltimoInfoExtraDireccionUtilizada);
            setAddressReference(UltimaReferenciaDireccionUtilizada);
            setComunasList(comunasList);
            

            if(UltimaCiudadUtilizada && UltimaComunaUtilizada && UltimaDireccionUtilizada && UltimoNumeroDireccionUtilizada){

                const addressExtraText = UltimoInfoExtraDireccionUtilizada || '';
                const _LasAdressFullText = `${UltimaDireccionUtilizada} ${UltimoNumeroDireccionUtilizada} ${addressExtraText}, ${UltimaComunaUtilizada}`.toLocaleUpperCase();
                
                setLasAdressFullText(_LasAdressFullText);
                setLastRegionSubdivition(UltimaComunaUtilizada);
                setLastAddress(UltimaDireccionUtilizada);
                setLastAddressNumber(UltimoNumeroDireccionUtilizada);
                setLastAddressExtra(addressExtraText);
                setLastAddressReference(UltimaReferenciaDireccionUtilizada);

            } else {

                setCanUseOldAddress(false);
                setIsNewAddress(true);
                setShowAddressMap(false);
                setAddressMapConfirmed(false);

            }

            setComunasList(comunasList);
            setUserHasAllMandatoryProfileData(DatosCompletos);
            setIsLoadingUserData(false);

        }

        if(userIsLoggedInAndReady){
            getUsrInformation();
        }

        return () => {}

    },[userIsLoggedInAndReady])

    useEffect(()=>{

        /* console.log(address, addressNumber, regionSubdivition, city) */

        if(address === '' || !address) return setShowAddressMap(false);
        if(addressNumber === '' || !addressNumber) return setShowAddressMap(false);
        if(regionSubdivition === '' || !regionSubdivition) return setShowAddressMap(false);
        if(city === '' || !city) return setShowAddressMap(false);

        setaddressMap(`https://maps.google.com/maps?q=${address}%20${addressNumber}%2C%20${regionSubdivition}&t=m&z=16&output=embed&iwloc=near`);
        setShowAddressMap(true);
        setAddressMapConfirmed(false);

    },[address, addressNumber, regionSubdivition, city])

    useEffect(()=>{

        if(regionSubdivition !== '' && regionSubdivition !== undefined && shoppingCart.shoppingCart.totalPrice > 0){

            try {

                getDeliveryCostUseCase(shoppingCart.shoppingCart.totalPrice, regionSubdivition).then(res => {
                    
                    setDeliveryCost(res.cost);
    
                }).catch(err=> {
                    console.error(err);
                })
    
            } catch(e){
    
                console.error(e)
    
            }
        }

        return () => {}

    }, [regionSubdivition, shoppingCart.shoppingCart.totalPrice]);


    const addressSelection = (e:any):void => {

        const value = e.target.value;
        if(value === 'new') {
            
            setCity('Santiago');
            setRegionSubdivition('');
            setAddress('');
            setAddressNumber('');
            setAddressExtra('');
            setAddressReference('');
            setIsNewAddress(true);
            setAddressMapConfirmed(false);
        }

        if(value === 'latest') {
            
            setCity('Santiago');
            setRegionSubdivition(lastRegionSubdivition);
            setAddress(lastAddress);
            setAddressNumber(lastAddressNumber);
            setAddressExtra(lastAddressExtra);
            setAddressReference(lastAddressReference);
            setIsNewAddress(false);
            setAddressMapConfirmed(false);
        }

    }


    if(!auth?.user?.accessToken || auth.user.accessToken === null){
        localStorage.setItem('postLoginRedirection','/checkout');
        return <Redirect to='/login'  />
    }

    if(isLoadingUserData) {
        return (<>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            <CheckoutSkeletonComponent />
        </>)
    }

    localStorage.setItem('showCheckoutIncompleData', "0");
    //localStorage.setItem('showBackToCheckoutInAccountPage', "0");

    if(!userHasAllMandatoryProfileData){

        localStorage.setItem('showCheckoutIncompleData', "1");
        //localStorage.setItem('showBackToCheckoutInAccountPage', "1");
        //localStorage.setItem('postLoginRedirection','/checkout');
        alert("Para ingresar su pedido, por favor complete su perfil");
        return <Redirect to='/account/' />

    }

    if(isSubmitingOrder) {
        return (
            <div className="content-wrapper" style={{minHeight:'500px'}}>
                <SubmitingOrderSkeletonComponent />
            </div>
        )
    }

    /* if(showSuccess){
        return <Redirect to='/account/orders/' />
    } */

    if(shoppingCart.shoppingCart.products.length <= 0) {
        return <SideCart />
    }

    return (<div className="content-wrapper">

        <div className="checkout-container">
            <h1>Finalizar pedido</h1>

            <div className="spacer h30">&nbsp;</div>

            <div>
                <h2 className='form-title'>Datos de contacto</h2>
            </div>

            <div className="spacer h30">&nbsp;</div>

            <div className="checkout-input">
                <label>Persona de contacto</label>
                <InputComponent {...nameFieldProps} />
            </div>

            <div className="checkout-input">
                <label>Celular de contacto</label>
                <InputComponent {...phoneFieldProps} />
            </div>


            <div className="spacer h30">&nbsp;</div>

            <div>
                <h2 className='form-title'>Despachar a:</h2>
            </div>

            <div className="spacer h30">&nbsp;</div>

            {canUseOldAddress && <div className="checkout-input last-address-select">
                <select onChange={e=>addressSelection(e)} className="checkout-select last-address-select">
                    <option value="latest">{lasAdressFullText}</option>
                    <option value="new">Nueva dirección</option>
                </select>
            </div>}

            
            {!isNewAddress && <>
            
            
                <div className="checkout-input preselected-delivery-address">
                    <p>
                        <span><b>Calle:</b> {address}</span><br/>
                        <span><b>Número:</b> {addressNumber}</span><br/>
                        <span><b>Depto/Block/Villa:</b> {addressExtra}</span><br/>
                        <span><b>Comuna:</b> {regionSubdivition}</span><br/>
                        <span><b>Ciudad:</b> {city}</span><br/>
                    </p>
                </div>
            
            </>}
            
            {isNewAddress && <>

            <div className="checkout-input">
                <label>Comuna</label>
                <select onChange={e => setRegionSubdivition(e.target.value) }>
                    <option value="">Seleccione comuna</option>
                    {comunasList.map((comuna,key) =>
                        <option key={key} value={comuna.comuna}>
                            {comuna.comunaText}
                        </option>
                    )}
                </select>

            </div>

            <div className="checkout-input">
                <label>Calle</label>
                <InputComponent {...addressFieldProps} />
            </div>

            <div className="checkout-input">
                <label>Número calle</label>
                <InputComponent {...addressNumberFieldProps} />
            </div>

            <div className="checkout-input">
                <label>Depto/Block/Villa</label>
                <InputComponent {...addressExtraFieldProps} />
            </div>

            <div className="checkout-input references">
                <label>Referencias dirección</label>
                <InputComponent {...addressReferenceFieldProps} />
            </div>

            </>}

            <div className="spacer h30">&nbsp;</div>

            {showAddressMap && !addressMapConfirmed &&
            <div style={{width: '100%'}}>

                <div>
                    <iframe style={{width: '100%'}} title="Delivery address" width="100%" height="250" id="gmap_canvas" src={addressMap} frameBorder="0" scrolling="no"></iframe>
                </div>

                <div id="checkout-confirm-adress-buttons">
                    <button onClick={e=>setAddressMapConfirmed(true)}>Haga click aquí para confirmar la dirección</button>
                </div>

            </div>}

            
            
        </div>

        <div className="checkout-container checkout-cart-container">

            <div className="checkout-product-list">
                <SideCart {...{deliveryCost}} />
            </div>

            <div className='pago-title'>
                <h2 className='form-title'>Método de pago</h2>
            </div>

            <div className="checkbox">
                
                <span className='payment-method-radio'><input className='payment-method-radio' id="paymethod-1" onChange={e => setPaymentMethod(e.target.value)} value="Transbank" type="radio" name="client-type-payment" defaultChecked /> Transbank / Tarjeta</span>
                <span className='payment-method-radio'><input className='payment-method-radio' id="paymethod-2" onChange={e => setPaymentMethod(e.target.value)} value="Transferencia" type="radio"  name="client-type-payment" /> Transferencia</span>

            </div>

            {!addressMapConfirmed &&<p className='warning-info'>
                Para poder confirmar tu pedido, por favor ingresa tu dirección y confírmala en el mapa.    
            </p>}

            {!addressMapConfirmed && <div className="checkout-buttons">
                <button disabled={true} id="finish-order" style={{"backgroundColor": '#e00c14', 'borderColor': '#e00c14', "cursor": "not-allowed"}}>Confirmar pedido</button>
            </div>}

            {addressMapConfirmed && <div className="checkout-buttons">
                <button className="scaleupanddown" id="finish-order" onClick={e=>submitOrder(e)}>Confirmar pedido</button>
            </div>}

        </div>

    </div>);
}

export default Checkout;