import React, {FC} from 'react'
import InputComponent from '../atoms/Input'
import IconComponent from '../atoms/Icon'


const SearchBox:FC<any> = (props:any) => {

    const searchFieldProps = {
        fieldValue: '',
        onChangeFunc: (e:any)=>{}
    }

    return <div className="search-box">
        <InputComponent {...searchFieldProps} />
        <IconComponent type="search" />
    </div>
}

export default SearchBox