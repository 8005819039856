import React, {FC, useState} from 'react';

/* import { Providers, SignInWithSocialMedia, loginWithEmailAndPassword } from '../../providers/auth/firebase/firebase';
import { IUserAuth, StoreContext } from '../../../../store/AppContent';
import IImageComponent, {IImage} from '../atoms/Image';
import { useHistory } from "react-router-dom";
 */
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


import InputComponent from '../atoms/Input';
import { IInput } from '../atoms/Input';
import { Link, useHistory} from 'react-router-dom';
import { resetPassword } from '../../providers/auth/firebase/firebase';

const PasswordRecoveryComponent:FC<any> = (props:any) => {

    const [email, setEmail] = useState('');
    const [submiting, setSubmiting] = useState<boolean>(false);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { push } = useHistory();

    const usernameFieldProps:IInput = {
        fieldValue: email,
        onChangeFunc:setEmail
    }
    
    if(submiting) {
        return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={submiting}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
        )
    }

    const handleClose = (e:any) => {
        setEmailSent(false);
        push('/login');
    }

    if(emailSent) {
        return (<>
        
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={emailSent}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                    {"Recuperar password"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Hemos enviado un correo a {email} con instrucciones para recuperar su contraseña.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Aceptar
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        
        </>)
    }

    return (<>

        <div id="login-options-container">

            <div id="custom-login-form">

                <div className='input-login'>
                    <label>Email</label>
                    <InputComponent {...usernameFieldProps} />
                </div>

                <button className='btn-login' onClick={e=> {
                    setSubmiting(true);
                    resetPassword(email).then(res => {
                        setSubmiting(false);
                        setEmailSent(true);
                    }).catch(err => console.log("err", err));
                }}>
                    Recuperar contraseña
                </button>

                {<Link to="/login">
                    <button className='btn-forgotten-password'>
                        Volver
                    </button>
                </Link>}

            </div>

            
        </div>

    </>)
}

export default PasswordRecoveryComponent