import React, {FC, useContext, useEffect} from 'react'
import SideCart from '../../../../Cart/infraestructure/components/molecules/SideCart'
import ProductCatalog from '../../../../Product/infraestructure/components/organism/ProductCatalog'
import { StoreContext } from '../../../../store/AppContent'

const Catalog:FC<any> = (props?:any) => {

    const {catalog} = useContext(StoreContext);
    const category = props?.match?.params?.category;

    useEffect( () => {

        if(category !== '' && category !== undefined && catalog.catalog.list.length > 0){
            catalog.catalogDispatch({
                type: 'FILTER_CURRENT_CATALOG',
                payload: {
                    catalog: catalog.catalog,
                    filter_category: category
                }
            });
        } 
        
        return () => {}

    },[category, catalog.catalog.list]);
    

    return (<div className="content-wrapper">
    
        <div className="catalog-container">
            <ProductCatalog {...catalog.catalog} />
        </div>

        <div className="cart-container catalog">
            <SideCart />
        </div>
    
    </div>);
}

export default Catalog;