import { FC } from "react";
import { Link } from "react-router-dom";

const OrderPlacedPage:FC = () => {
    return (<>

        <div className="content-wrapper">
            <div className="checkout-container full">
                <h1 className="checkout-login-message">Gracias por tu pedido</h1>
                <p className="checkout-login-message">Recuerda revisar tu correo para saber el total de tu pedido.</p>
                <Link to="/account/orders" className='button go-to-orders'>
                    Ver mis pedidos ingresados
                </Link>
            </div>
        </div>

    </>)
}

export default OrderPlacedPage;