import { getMethod } from "../../../Common/domain/services/IHttpServiceMethods";
import HttpClient from "../../../Common/infrastructure/services/httpClient";

export default class ProductAPIContract extends HttpClient implements getMethod {

    private static classInstance?: ProductAPIContract;

    constructor(protected API_BASE_URL:string){
        super(API_BASE_URL);
    }

    public static getInstance(API_BASE_URL:string) {

        if (!this.classInstance) {
          this.classInstance = new ProductAPIContract(API_BASE_URL);
        }
    
        return this.classInstance;

    }

    get(query:string, headers:object):Promise<any> {
        // return new Promise((resolve, reject) => reject(new Error("get method not implemented")))
        return this.instance.get(`productos?${query}`, headers);
    }

}