import React, {FC} from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export interface IImage {
    alt: string,
    height: number,
    width: number,
    src: string,
    onClick?:Function
}

const IImageComponent:FC<IImage> = (image:IImage) => {

    const func = image.onClick || function(){}

    return (
        <div>
            <LazyLoadImage
                onClick={e => func()}
                alt={image.alt}
                height={image.height}
                src={image.src} // use normal <img> attributes as props
                width={image.width} />
        </div>
    )
}

export default IImageComponent;