import { FC, useState, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { IInput } from "../atoms/Input"
import { IUserAuth } from "../../../../store/AppContent";
import firebase from 'firebase/compat/app';
import { auth as authFB} from '../../providers/auth/firebase/firebase';
/* import { refreshAccessToken } from "../../providers/auth/firebase/firebase"; */
import axios from "axios";
import { StoreContext } from "../../../../store/AppContent";
import { createAccountWithEmailAndPassword/* , onAuthStateChange */ } from "../../providers/auth/firebase/firebase";
import InputComponent from "../atoms/Input";
/* import { getRequestsHeaders } from "../../providers/auth/firebase/firebase"; */
import { viewUserInformationUseCase } from "../../../../Clients/application/useCases/viewUserInformation";
import LoaderComponent from "../molecules/Loader";
/* import { Redirect } from "react-router-dom"; */
import SubmitingRegistrationProgress from "./SubmitingRegistrationProgress";

const EmailPasswordRegisterComponent:FC = (props:any) => {

    const {auth} = useContext(StoreContext);
    let history = useHistory();

    const [newUsername, setNewUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isLoadingUserData, setIsLoadingUserData] = useState(true);
    const [isSubmitingRegistration, setIsSubmitingRegistration] = useState(false);
    /* const [userIsLoggedInAndReady, setUserIsLoggedInAndReady] = useState(false); */

    /* const [rut, setRUT] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState(''); */

    /* const phoneFieldProps:IInput = {
        fieldValue: phone,
        onChangeFunc:setPhone
    }

    const nameFieldProps:IInput = {
        fieldValue: name,
        onChangeFunc:setName
    }

    const rutFieldProps:IInput = {
        fieldValue: rut,
        onChangeFunc:setRUT
    } */

    const newUsernameFieldProps:IInput = {
        fieldValue: newUsername,
        onChangeFunc:setNewUsername,
        inputType: 'email'
    }

    const newPasswordFieldProps:IInput = {
        fieldValue: newPassword,
        onChangeFunc:setNewPassword,
        inputType: 'password'
    }

    useEffect(() => {
        setIsLoadingUserData(false);
        return () => { };
    }, []);


    /* useEffect(()=>{

        if(userIsLoggedInAndReady){
            history.push('/account');
        }
        
        setIsLoadingUserData(false);

        return ()=>{}

    }, [userIsLoggedInAndReady]); */


    const setLoggedUser = (payload:IUserAuth) => {

        if(payload.accessToken !== null){
            
            auth.userAuthDispatch({
                type: 'SET_USER',
                payload : payload
            });

            // TODO Mover a un caso de uso
            localStorage.setItem('userInfo',JSON.stringify(payload));

        }
    }

    // TODO Llevar a un useCase con su respectivo adaptador
    const saveNewUser = async (payload:IUserAuth, authHeader:string) => {

        try {
            
            const URL:string = process.env.REACT_APP_EMAIL_PASSWORD_REGISTER || '';
            const userCreatingPayload = {name:'', username:'', password: '', ...payload}
            
            return await axios.post(URL, userCreatingPayload, {
                
                headers: {
                    "Authorization": `Bearer ${authHeader}`
                }

            }).then(res => res.data).catch(e => {

                throw new Error(e);
                
            });

        } catch(e) {

            console.error("Error al guardar el usuario recien creado", e)

        }

    }

    const userPasswordRegistration = async () => {

        try {

            if(newPassword === '' || newUsername === ''){
                return alert("Debe ingresar un usuario y una contraseña");
            }
        
            const registrationResult:firebase.auth.UserCredential = await createAccountWithEmailAndPassword(newUsername, newPassword);
            
            const uid:string|undefined = registrationResult.user?.uid;
            const email = registrationResult.user?.email;
            const accessToken:string|undefined = await registrationResult.user?.getIdToken();
    
            const photoURL:string = '';
            const RUT:string = '';
            const phoneNumber:string = '';
            const displayName:string|undefined = '';
    
            if(uid === undefined || email === undefined || accessToken === undefined) {
                throw new Error("Hubo un problema técnico al registrar tu cuenta");
            }

            localStorage.setItem('isCompletingSignUp', "1");
            
            window.scroll(0,0);
            setIsSubmitingRegistration(true);
    
            const userInfoPayload = {
                accessToken,
                uid,
                displayName,
                email,
                phoneNumber,
                photoURL,
                RUT
            };

            setLoggedUser(userInfoPayload);
    
            const saveUserResult:any = await saveNewUser(userInfoPayload, accessToken);

            console.log("saveUserResult", saveUserResult);

            if(saveUserResult["status"] === true){

                const userInformation = await viewUserInformationUseCase(uid);

                console.log("++++++++++++ userInformation", userInformation);

                const { userProviderID } = userInformation;
                if(userProviderID) {
                    setIsSubmitingRegistration(false);
                    return history.push("/account/");
                }
    
                let i = 0;
                let maxRetry = 20;
                let timer = setInterval(async function() {
                
                    i++;
    
                    const userInfo:any = await viewUserInformationUseCase(uid);
    
                    if(userInfo.hasOwnProperty('id') && userInfo.hasOwnProperty('Email')&& userInfo.hasOwnProperty('ProviderId')){
    
                        const id = userInfo.id;
                        const username = userInfo.Email;
                        const providerId = userInfo.ProviderId;
    
                        if(Math.abs(id) > 0 && username === email && providerId === uid){
                            
                            setIsSubmitingRegistration(false);
                            history.push("/account/");
                            clearInterval(timer);
                            return true;
    
                        }
    
                    }
    
                    if (i === maxRetry) {
    
                        alert("Hubo un problema a generar su cuenta de usuario en Susaron");
                        setIsSubmitingRegistration(false);
                        clearInterval(timer);
                    }
    
                }, 3000);
    
            }
    
        } catch(e:any){
    
            console.log(e);

            const errorCode = e["code"];
            
            if(errorCode === 'auth/email-already-in-use') {
                return alert("Ese correo ya está registrado.")
            }

            if(errorCode === 'auth/invalid-email') {
                return alert("El correo ingresado no es válido.");
            }
    
            if(errorCode === 'auth/weak-password') {
                return alert("La contraseña es poco segura. La longitud mínima es de 6 caracteres.")
            }
    
            /* console.error(errorCode); */
    
            setIsLoadingUserData(false);
            localStorage.setItem('isCompletingSignUp', "0");

            authFB.signOut().then(res => {
                console.log("signOute exitoso", res)
            }).catch(err => {
                console.error("signOutError", err);
            })
            
            localStorage.setItem('currentPage','logout');
            localStorage.removeItem('userInfo');
            
            auth.userAuthDispatch({
                type: "LOGOUT",
                payload:{
                    accessToken:null,
                    uid: null,
                    displayName: null,
                    email: null,
                    phoneNumber: null,
                    photoURL:null
                }
            });

            alert("Hubo un problema al crear tu cuenta.");

    
        }
    
    }

    if(isLoadingUserData) {
        return <LoaderComponent />
    }

    if(isSubmitingRegistration) {
        return <SubmitingRegistrationProgress />
    }

    
    return (
        <>
        
        <div id="custom-registration-form">

            {/* <div className='input-login'>
                <label>Nombre</label>
                <InputComponent {...nameFieldProps} />
            </div>

            <div className='input-login'>
                <label>RUT</label>
                <InputComponent {...rutFieldProps} />
            </div>

            <div className='input-login'>
                <label>Teléfono</label>
                <InputComponent {...phoneFieldProps} />
            </div> */}
            <h2>Paso 1: <br/>Ingresa tus datos de acceso.</h2>

            <div className='input-login'>
                <label>Email</label>
                <InputComponent {...newUsernameFieldProps} />
            </div>

            <div className='input-login'>
                <label>Password</label>
                <InputComponent {...newPasswordFieldProps} />
            </div>

            <button className="btn-register" onClick={e=>userPasswordRegistration()}>
                Registrarme en Susaron
            </button>


        </div>
        
        </>

    )

}

export default EmailPasswordRegisterComponent