import { getRequestsHeaders, refreshAccessToken } from "../../../Common/infrastructure/providers/auth/firebase/firebase";
import axios from "axios";

export const getDeliveryCostUseCase = async(orderTotal:number, comuna:string) => {

    try {

        const URL = process.env.REACT_APP_GET_DELIVERY_COST || '';
        await refreshAccessToken();
        const headers = getRequestsHeaders();
        const payload = {orderTotal, comuna}

        const deliveryCost  = await axios.post(URL, payload, headers).then(res => res.data).catch(e => {
            throw new Error(e);
        });

        return deliveryCost;

    } catch(e){
        
        console.error(e);
        alert("Hubo un problema al obtener el costo de envío");
        return [];

    }


}