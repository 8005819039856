import React, {FC} from "react";

interface IbuttonProps {
    onClick:any
}
interface IButtonComponent {
    text: string
    buttonProps:IbuttonProps
}

const ButtonComponent:FC<IButtonComponent> = (props:IButtonComponent) => {
    const {text, buttonProps} = props;
    return <button {...buttonProps}>{text}</button>
}

export default ButtonComponent;