import IuseCase from "../../../Common/domain/useCases/IuseCase";
import { IProductRepository } from "../../domain/repository/ProductRepository";
import { IProductQueryInputParams } from "../adapters/listProductsAdapter";
import { IProductCatalogPromise } from "../adapters/listProductsAdapter";


export default class listProductsUseCase implements IuseCase {

    private productList:IProductCatalogPromise = {
        list:[],
        viewing:[]
    }

    constructor(private params:IProductQueryInputParams, private repo:IProductRepository){

    }

    execute(){
        const productList = this.repo.list(this.params.page);
        this.productList.list = productList
        this.productList.viewing = productList
    }

    result(){
        return this.productList;
    }
}