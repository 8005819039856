import useCaseAdapter from "../../../Common/domain/useCases/useCaseAdapter";
import { IShoppingCartProduct } from "../../domain/contract/ShoppingCartProduct";
import { IShoppingCart } from "../../domain/contract/ShoppingCart";
import { StorageRepository } from "../../infraestructure/repository/StorageRepository";
import { decrementProductQtyUseCase } from "../useCases/decrementProductQty";

export class decrementProductQtyAdapter implements useCaseAdapter {

    private useCase:decrementProductQtyUseCase;

    constructor(input:IShoppingCartProduct) {
        this.useCase = new decrementProductQtyUseCase(input, new StorageRepository());
        this.useCase.execute();
    }

    output():IShoppingCart{
        return this.useCase.result();
    }

}