import OrderAPIContract from "../../domain/services/OrderAPIContract";

export default class OrderAPI extends OrderAPIContract {

    constructor(protected API_BASE_URL:string){
        super(API_BASE_URL);
    }

    /* get(query:string, headers:object):Promise<any>{
        return this.instance.get(`pedidos?${query}`, headers);
    }

    detail(orderId:string,query:string, headers:object):Promise<any>{
        const url = `pedidos/${orderId}?${query}`;
        return this.instance.get(url, headers);
    }

    post(payload:object, query:string, headers:object){
        return this.instance.post(`pedidos?${query}`,payload,headers);
    } */

}