import React, {FC} from "react";
import IImageComponent, {IImage} from '../../../../Common/infrastructure/components/atoms/Image'

interface ICokingOptionComponent {
    option: string
}

const CookingOptionComponent:FC<ICokingOptionComponent> = (cookingOption:ICokingOptionComponent) => {
    
    const getImage = (image:string):IImage => {
        return {
            alt:"Opciones de preparación",
            height: 50,
            width: 50,
            src: `/static/${image}`,
        }
    }

    const {option} = cookingOption;
    let icon = <></>

    switch(option) {

        case 'horno':
            icon = <IImageComponent {...getImage("horno.png")} />;
            break;
        case 'parrilla':
            icon = <IImageComponent {...getImage("parrilla.png")} />;
            break;
        case 'sarten':
            icon = <IImageComponent {...getImage("sarten.png")} />;
            break;
        case 'olla':
            icon = <IImageComponent {...getImage("olla.png")} />;
            break;

    }

    return icon;


}

export default CookingOptionComponent