import React, {FC} from 'react';
import EmailPasswordRegisterComponent from '../../organisms/EmailPasswordRegister';

const SignUpPage:FC<any> = () => {

    return (<div className="content-wrapper"> 

            <div className="auth-container signup">
                <h1>Registro</h1>
                <EmailPasswordRegisterComponent />
            </div>

        </div>);

}

export default SignUpPage;