import React, {FC, useContext, useEffect} from 'react';
import { StoreContext } from '../../../../../store/AppContent';
import { useHistory } from 'react-router-dom';
import { auth as authFB} from '../../../providers/auth/firebase/firebase';
import LoaderComponent from '../../molecules/Loader';

const LogoutPage:FC<any> = (props:any) => {

    const {auth} = useContext(StoreContext);
    const history = useHistory();

    useEffect(()=>{

        authFB.signOut().then(res => {

            localStorage.setItem('currentPage','logout');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('cachedUserInfo');
            localStorage.removeItem('showBackToCheckoutInAccountPage');
            localStorage.removeItem('showCheckoutIncompleData');
            localStorage.removeItem('isCompletingSignUp');
            localStorage.removeItem('currentPage');
            
            auth.userAuthDispatch({
                type: "LOGOUT",
                payload:{
                    accessToken:null,
                    uid: null,
                    displayName: null,
                    email: null,
                    phoneNumber: null,
                    photoURL:null
                }
            });

            history.push('/login')
            
        }).catch(err => {

            console.error("signOutError", err);
        });

        return () => {}

    },[]);


    return <LoaderComponent />

}

export default LogoutPage;