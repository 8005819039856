import { refreshAccessToken } from "../../../Common/infrastructure/providers/auth/firebase/firebase";
import { getRequestsHeaders } from "../../../Common/infrastructure/providers/auth/firebase/firebase";
import { firestore } from "../../../Common/infrastructure/providers/auth/firebase/firebase";
import axios from "axios";
import IuseCase from "../../../Common/domain/useCases/IuseCase";
// import OrderAPIContract from "../../domain/services/OrderAPIContract";
import { ACOrderRepository } from "../../domain/repository/ACOrderRepository";
import { IOrder } from "../../domain/domain/order";

import { getUserId } from "../../../Clients/application/useCases/getUserIdUseCase";

export class _ListClientOrdersUseCase implements IuseCase {

    private clientOrders:any=[];

    constructor(private repo:ACOrderRepository){

    }

    execute(): void {
        this.clientOrders = this.repo.list(1);
    }

    result(): Promise<Array<IOrder>> {
        return this.clientOrders;
    }

}


export const ListClientOrdersUseCase = async () => {

    try {

        const userProviderId =  getUserId();
        console.log("userProviderId", userProviderId);

        if(userProviderId === 0) {
            return [];
        }

        const userOrdersFromFirestore = await firestore.collection("users").doc(userProviderId).collection('orders').orderBy('firestoreOrderId', 'desc').get();

        const firestoreUserOrders:any = userOrdersFromFirestore.docs.map(doc => doc.data());

        if(firestoreUserOrders.length >= 1){

            const rightFormatedOrder:any = firestoreUserOrders.map((order:any) => {
                return {
                    "id": order?.firestoreOrderId,
                    "CorreoClient": order?.email,
                    "Nombre": order?.name,
                    "DireccionEntrega": order?.address,
                    "ComunaEntrega": order?.comuna,
                    "Celular": order?.phone,
                    "PayloadPedido": order?.items,
                    "RUT": order?.rut,
                    "Ciudad": order?.city,
                    "ReferenciasDireccion": order?.address_reference || '',
                    "TipoCliente": order?.client_type,
                    "QuiereRecibirEmails": false,
                    "ComoConocioSusaron": '',
                    "TotalAprox": order?.total_aprox,
                    "TotalFinal": order?.total || 0,
                    "EstadoOrden": order?.order_status || 'RECIBIDA',
                    "NumeroCalle": order?.addressNumber,
                    "VillaBlockDepto": order?.addressExtra,
                    "PersonaContactoDelivery": order?.deliveryContactName || '',
                    "TelefonoContactoDelivery": order?.deliveryContactPhone || '',
                    "RelatedCommand": '',
                    "ProcesadoPorSusaron": order?.order_processed || false,
                    "Apellido": order?.lastname || '',
                    "TotalDespacho": order?.deliveryCostValue || 0,
                    "published_at": order?.published_at || (new Date(Number(order?.firestoreOrderId)) ?? undefined),
                    "created_at": order?.created_at ||  (new Date(Number(order?.firestoreOrderId)) ?? undefined),
                    "updated_at": order?.updated_at ||  (new Date(Number(order?.firestoreOrderId)) ?? undefined)
                }
            });

            if(!Object.values(rightFormatedOrder).map((order:any) => order.ProcesadoPorSusaron).includes(undefined)){
                console.log("[listClientOrders] List orders from firestore", rightFormatedOrder);
                return rightFormatedOrder;
            }

        }


        await refreshAccessToken();

        const URL = process.env.REACT_APP_LIST_CLIENT_ORDERS || '';
        const headers = getRequestsHeaders();
        const order  = await axios.get(URL, headers).then(res => res).catch(e => {
            throw new Error(e);
        });

        return order.data;

    } catch(e) {

        console.error(e);
        alert("Hubo un problema al obtener el listado de ordenes");
        return {};

    }
}