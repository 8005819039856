import { getRequestsHeaders, refreshAccessToken } from "../../../Common/infrastructure/providers/auth/firebase/firebase";
import axios from "axios";

export const getComunasListUseCase = async() => {

    try {

        const URL = process.env.REACT_APP_GET_COMUNAS || '';

        await refreshAccessToken();
        const headers = getRequestsHeaders();

        const comunasList  = await axios.get(URL, headers).then(res => res.data).catch(e => {
            throw new Error(e);
        });

        return comunasList;

    } catch(e){
        
        console.error(e);
        alert("Hubo un problema al obtener el listado de comunas");
        return [];

    }


}