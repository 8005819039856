import React, {FC} from "react";

interface ITitle {
    type: string,
    text: string
}

const TitleComponent:FC<ITitle> = (props:ITitle) => {

    const {type, text} = props;
    let title = <></>;

    switch(type) {

        case 'h1':
            title = <h1>{text}</h1>
            break;

        case 'h2':
            title = <h2>{text}</h2>
            break;

        case 'h3':
            title = <h3>{text}</h3>
            break;

        case 'h4':
            title = <h4>{text}</h4>
            break;

        case 'h5':
            title = <h5>{text}</h5>
            break;

    }

    return title
}

export default TitleComponent;