import axios from "axios"
import { getRequestsHeaders } from "../../../Common/infrastructure/providers/auth/firebase/firebase"

export const UpdateUserInformationUseCase = async (id:string, payload:any) => {

    try {
        
        console.log(payload);

        const URL = `${process.env.REACT_APP_UPDATE_USER_INFORMATION}/${id}/`;
        const headers = getRequestsHeaders();
        const updateResult = await axios.put(URL, payload, headers).then(res => res.data).catch(err => {
            throw new Error(err);
        })

        return updateResult;

    } catch(e) {

        console.error("Error UpdateUserInformation: ", e);
        return e;

    }

}