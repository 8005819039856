import { FC } from "react";
/* import IImageComponent from "../atoms/Image"; */
/* import { IImage } from "../atoms/Image"; */

const LoaderComponent:FC = (props:any) => {

    /* const {loaderClassName} = props */
    
    /* const logo:IImage = {
        alt:"Susaron",
        height: 95,
        width: 150,
        src:"/static/logo-susaron-borde.png",
    } */

    return <></>

    /* return (<>
        <div id="loader" className={loaderClassName}>
            
            <div>
                
                <figure>
                    <IImageComponent {...logo} />
                    <figcaption>
                        Por favor espere...
                    </figcaption>
                </figure>

            </div>

        </div>
    </>) */
}

export default LoaderComponent;