import React, {FC} from 'react'
import ButtonComponent from '../../../../Common/infrastructure/components/atoms/Button';
import { IImage } from '../../../../Common/infrastructure/components/atoms/Image';

export interface IProductCartQuantityComponent {
    currentStock: number,
    productId: number,
    image:IImage,
    name:string,
    price:number,
    increaseFunc:Function,
    decreaseFunc:Function,
    cartCurrentQuantity: number
}

const SetCartProductQuantityComponent:FC<IProductCartQuantityComponent> = (props:IProductCartQuantityComponent) => {

    
    const {increaseFunc, decreaseFunc, cartCurrentQuantity} = props;

    const buttonAdd = {
        text: "+",
        buttonProps: {
            onClick: ():Function => increaseFunc(),
            className: "add-to-cart-button"
        }
    }

    const buttonDecrease = {
        text: "-",
        buttonProps: {
            onClick: ():Function => decreaseFunc(),
            className: "add-to-cart-button"
        }
    }

    return (<div className="add-to-cart">

        <ul>
            <li>
                <ButtonComponent {...buttonDecrease} />
            </li>
            <li>
                <span className="add-to-cart-quantity">{cartCurrentQuantity}</span>
            </li>
            <li>
                <ButtonComponent {...buttonAdd} />
            </li>
            {/* <li className="basket-icon">
                <IImageComponent {...carrito} />
            </li> */}
        </ul>

    </div>)
}

export default SetCartProductQuantityComponent