// Add to cart buisiness logic

import { IShoppingCart } from "../contract/ShoppingCart";
import { IShoppingCartProduct } from "../contract/ShoppingCartProduct";

export class ShoppingCart {

    constructor(private cart?:IShoppingCart) {

    }

    groupShoppingCartProducts(hash:any){
        return function (r:any, a:any) {
            (hash[a.id] = hash[a.id] || r[r.push({ id: a.id, name: a.name, measure_unit: a.measure_unit, sku: a.sku, price: a.price, image: a.image, unit_multiplier:a.unit_multiplier, qty: 0 }) - 1]).qty += a.qty;
            return r;
          };
    }

    _cart():IShoppingCart {

        if(!this.cart) {
            
            return {
                totalPrice:0,
                totalProducts:0,
                products:[]
            }
            
        }

        return this.cart;
    }

    getNewShoppingCart(newProductList:Array<IShoppingCartProduct>):IShoppingCart{

        var data = newProductList,
        newProductListGrouped = data.reduce( this.groupShoppingCartProducts(Object.create(null)), []);

        let newShoppingCart = {
            totalPrice: newProductListGrouped.reduce((acc:number, prod:IShoppingCartProduct) => acc + (prod.price * prod.qty * prod.unit_multiplier ), 0),
            totalProducts: newProductListGrouped.reduce((acc:number, prod:IShoppingCartProduct) => acc + prod.qty, 0),
            products:newProductListGrouped
        }

        newShoppingCart.totalPrice = Math.round(newShoppingCart.totalPrice);

        return newShoppingCart;
    }

    addProduct(product:IShoppingCartProduct){

        const cart = this._cart()
        const newProductList:Array<IShoppingCartProduct> = [...cart.products, product];
        const aNewCart = this.getNewShoppingCart(newProductList);
        this.cart = aNewCart;
        
    }

    decrementProductQuantity(product:IShoppingCartProduct):void{

        const cart = this._cart();
        const newProductList = cart.products.map((prd:IShoppingCartProduct) => {
            
            if(prd.id !== product.id){
                return prd;
            }

            if(prd.qty-1 <= 0){
                return prd;
            }

            prd.qty -= 1;
            return prd

        });

        const aNewCart = this.getNewShoppingCart(newProductList);
        this.cart = aNewCart;

    }


    incrementProductQuantity(product:IShoppingCartProduct):void{

        const cart = this._cart();
        const newProductList = cart.products.map((prd:IShoppingCartProduct) => {
            
            if(prd.id !== product.id){
                return prd;
            }

            if(prd.qty + 1 >= Number.MAX_SAFE_INTEGER){
                return prd;
            }

            prd.qty += 1;
            return prd

        });

        const aNewCart = this.getNewShoppingCart(newProductList);
        this.cart = aNewCart;

    }

    removeProduct(product:IShoppingCartProduct):void{
        const cart = this._cart();
        const newProductList = cart.products.filter(prd => prd.id !== product.id);
        const aNewCart = this.getNewShoppingCart(newProductList);
        this.cart = aNewCart;
    }


}