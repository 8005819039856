import useCaseAdapter from "../../../Common/domain/useCases/useCaseAdapter";
import { IOrder } from "../../domain/domain/order";
import { APIOrderRepository } from "../../infrastructure/repository/APIOrderRepository";
import OrderAPI from "../../infrastructure/services/OrderAPI";
import PlaceOrderUseCase from "../useCases/placeOrder";


export class PlaceOrderAdapter implements useCaseAdapter {

    private useCase:PlaceOrderUseCase;

    constructor(input:IOrder) {

        const api = OrderAPI.getInstance(process.env.REACT_APP_GATEWAYAPI_URL || '');
        const repo = new APIOrderRepository(api);
        this.useCase = new PlaceOrderUseCase(input, repo);
        this.useCase.execute();
    }

    output():IOrder{
        return this.useCase.result();
    }

}