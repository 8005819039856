import React, {FC, useState, useEffect} from 'react';
/* import ContactSkeletonComponent from '../organisms/ContactSkeleton';
import { IInput } from '../atoms/Input';
import InputComponent from '../atoms/Input';
*/

const listadoComunas:any = [
    {
      "comuna": "estacion-central",
      "comunaText": "Estación central",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 30000
    },
    {
      "comuna": "la-florida",
      "comunaText": "La Florida",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 30000
    },
    {
      "comuna": "la-reina",
      "comunaText": "La Reina",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 30000
    },
    {
      "comuna": "las-condes",
      "comunaText": "Las Condes",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 30000
    },
    {
      "comuna": "lo-barnechea",
      "comunaText": "Lo Barnechea",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 30000
    },
    {
      "comuna": "macul",
      "comunaText": "Macul",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 30000
    },
    {
      "comuna": "nunoa",
      "comunaText": "Ñuñoa",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 30000
    },
    {
      "comuna": "panalolen",
      "comunaText": "Peñalolén",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 30000
    },
    {
      "comuna": "providencia",
      "comunaText": "Providencia",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 30000
    },
    {
      "comuna": "santiago",
      "comunaText": "Santiago",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 30000
    },
    {
      "comuna": "vitacura",
      "comunaText": "Vitacura",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 30000
    },
    {
      "comuna": "conchali",
      "comunaText": "Conchalí",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "cerrillos",
      "comunaText": "Cerrillos",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "huechuraba",
      "comunaText": "Huechuraba",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "independencia",
      "comunaText": "Independencia",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "la-cisterna",
      "comunaText": "La Cisterna",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "lo-prado",
      "comunaText": "Lo Prado",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "maipu",
      "comunaText": "Maipu",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "pedro-aguirre-cerda",
      "comunaText": "Pedro Aguirre Cerda",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "pudahuel",
      "comunaText": "Pudahuel",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "quilicura",
      "comunaText": "Quilicura",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "quinta-normal",
      "comunaText": "Quinta Normal",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "recoleta",
      "comunaText": "Recoleta",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "renca",
      "comunaText": "Renca",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "san-joaquin",
      "comunaText": "San Joaquín",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "san-miguel",
      "comunaText": "San Miguel",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "san-ramon",
      "comunaText": "San Ramón",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 40000
    },
    {
      "comuna": "malloco",
      "comunaText": "Malloco",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 50000
    },
    {
      "comuna": "padre-hurtado",
      "comunaText": "Padre Hurtado",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 50000
    },
    {
      "comuna": "panaflor",
      "comunaText": "Peñaflor",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 50000
    },
    {
      "comuna": "puente-alto",
      "comunaText": "Puente Alto",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 50000
    },
    {
      "comuna": "san-bernardo",
      "comunaText": "San Bernardo",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 50000
    },
    {
      "comuna": "talagante",
      "comunaText": "Talagante",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 50000
    },
    {
      "comuna": "colina",
      "comunaText": "Colina",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 70000
    },
    {
      "comuna": "lampa",
      "comunaText": "Lampa",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 70000
    },
    {
      "comuna": "buin",
      "comunaText": "Buín",
      "deliveryCostIfNotFree": 5000,
      "minimumToFree": 70000
    }
]

const ShippingZonesPage:FC<any> = () => {

    const [comunas, setComunas] = useState<Array<any>>([]);

    const nicePrice = (price:number) => {
        return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(price);
    }

    useEffect(() => {
        setComunas(listadoComunas)
    },[]);

    return (<>
    
        <div className="content-wrapper">

            <div className="shipping-zones-container full">
                <h1>Zonas de envío</h1>
                <ul>
                    {comunas.map((comuna:any, key: number) => 
                    
                        <li key={key}>{comuna.comunaText}, {nicePrice(comuna.deliveryCostIfNotFree)}, <b>gratis desde {nicePrice(comuna.minimumToFree)}</b></li>
                    
                    )}
                </ul>
            </div>

        </div>

    
    </>)
}

export default ShippingZonesPage;