import React, {FC} from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { Backdrop } from '@mui/material';


const SubmitingRegistrationProgress:FC<any> = (props:any) => {
    
    const {ending} = props;

    return (<>

        <Backdrop
            sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <Box sx={{ width: '350px', color: 'black'}}>
                <h4 style={{marginBottom: '7px', color: 'white', fontWeight: '400'}}>
                    {ending ? 'Finalizando registro' : 'Estamos creando su cuenta...'}
                </h4>
                <LinearProgress sx={{
                    bgcolor: "white",
                }} variant="indeterminate" color="inherit" />
            </Box>
        </Backdrop>

    </>)
}

export default SubmitingRegistrationProgress;