import React, {FC} from 'react'
/* import IImageComponent from '../atoms/Image' */
/* import { IImage } from '../atoms/Image' */
import {Link} from 'react-router-dom';

const Footer:FC<any> = (props:any) => {

    /* const logo:IImage = {
        alt:"Susaron",
        height: 95,
        width: 150,
        src:"/static/logo-susaron-borde.png",
    } */

    return (
    <div className="page-footer">

        <div className="footer-content">
            {/* <div className="footer-brand-image">
                <IImageComponent {...logo} />
            </div> */}
            <div className="directions">
                <p>
                    
                    Oficina central: Avda.Matta #060 <br/>
                    Ñuñoa. Santiago de Chile<br/>
                    Teléfono: +56 2 23559814<br/>
                    Email: atucasa@susaron.cl
                    
                </p>
            </div>
            <div className="text-and-legal">
                
                <p className="footer-text">
                En Susaron a tu casa tenemos los mejores cortes de carne, pollo, cerdo y pavo y al mejor precio . El plazo máximo de entrega es de 48 horas, despacho solo a comunas con disponibilidad de entrega.
                </p>

                <p className="terms">
                    <Link to="/about">
                        Términos y condiciones
                    </Link>
                </p>

                <p className="terms">
                    <Link to="/contact">
                        Contacto
                    </Link>
                </p>
                <p className="terms">
                    <a target="_blank" rel="noreferrer" href="https://susaron.cl/susaron-ventas-mayorista/">
                        Mayoristas
                    </a>
                </p>

            </div>
        </div>
        
        <div className="footer-red-end">
            <p>Susaron 2022 - Todos los derechos reservados</p>
        </div>

    </div>)
}

export default Footer