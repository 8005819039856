import React, {FC} from "react";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const ClientPersonalDataSkeletonComponent:FC = (props:any) => {
    
    return (
        <>
        
            <div className="checkout-input checkbox">

                <h2><Skeleton sx={{ bgcolor: 'grey.500' }} height={25} width="100%" variant="text" /></h2>
                
                <div>
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={25} width={100} variant="text" />
                </div>

                <Skeleton sx={{ bgcolor: 'grey.500' }} height={15} width={50} variant="text" />
                <Skeleton sx={{ bgcolor: 'grey.500' }} height={15} width={50} variant="text" />

            </div>

            <div className='account-data-input'>
                <Stack spacing={1}>
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={20} width={150} variant="text" />
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                </Stack>
            </div>

            <div className='account-data-input'>
                <Stack spacing={1}>
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={20} width={150} variant="text" />
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                </Stack>
            </div>

            <div className='account-data-input'>
                <Stack spacing={1}>
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={20} width={150} variant="text" />
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                </Stack>
            </div>

            <div className='account-data-input'>
                <Stack spacing={1}>
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={20} width={150} variant="text" />
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={50} variant="text" />
                </Stack>
            </div>

            <div className="checkbox">
                <Skeleton sx={{ bgcolor: 'grey.500' }} height={15} width={250} variant="text" />
            </div>

            <div className="checkout-input">
                <Stack spacing={1}>
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={20} width={150} variant="text" />
                    <Skeleton sx={{ bgcolor: 'grey.500' }} height={40} variant="text" />
                </Stack>
            </div>
            
            <Skeleton sx={{ bgcolor: 'grey.500' }} height={60} width="100%" variant="text" />

        </>
    )

}

export default ClientPersonalDataSkeletonComponent;