import React, {FC, useContext} from 'react';
import { Link} from 'react-router-dom';
import SideCart from '../../../../Cart/infraestructure/components/molecules/SideCart';
import { StoreContext } from '../../../../store/AppContent';


const Cart:FC<any> = (props:any) => {

    const {shoppingCart} = useContext(StoreContext);

    return (<div className="content-wrapper">
        
        <div className="cart-page-container">
            <h1>Carrito</h1>
            <div className="spacer h30">&nbsp;</div>
            <SideCart />
        </div>

        <div className="cart-page-buttons">

            {shoppingCart.shoppingCart.products.length > 0 &&
                <div className="checkout-button">
                    <Link to="/checkout">
                        Finalizar pedido
                    </Link>
                </div>
            }

            
            <Link className="button" to="/productos">
                Seguir comprando
            </Link>

            {shoppingCart.shoppingCart.products.length === 0 && <Link className="button reverse duplicate-order" to="/account/orders/">
                Repetir pedido anterior
            </Link>}

        </div>

    </div>)
}

export default Cart