import { getMethod, postMethod } from "../../../Common/domain/services/IHttpServiceMethods";
import HttpClient from "../../../Common/infrastructure/services/httpClient";

export default class OrderAPIContract extends HttpClient implements getMethod, postMethod {

    private static classInstance?: OrderAPIContract;

    constructor(protected API_BASE_URL:string){
        super(API_BASE_URL);
    }

    public static getInstance(API_BASE_URL:string) {

        if (!this.classInstance) {
          this.classInstance = new OrderAPIContract(API_BASE_URL);
        }
    
        return this.classInstance;

    }

    get(query:string, headers:object):Promise<any> {
        /* console.log(headers); */
        // return new Promise((resolve, reject) => reject(new Error("get method not implemented")))
        return this.instance.get(`/orders?${query}`, headers);
    }

    post(payload:object, query:string, headers:object){
        /* console.log(headers); */
        return this.instance.post(`/orders?${query}`, payload, headers);
    }

}