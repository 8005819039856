import React, {FC} from "react";
/* import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack'; */
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { Backdrop } from "@mui/material";


const SubmitingOrderSkeletonComponent:FC = (props:any) => {
    
    const {showSuccess} = props;

    
    if(showSuccess) {
    
        return (<>
        
            <Backdrop
                sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}>
                <Box sx={{ width: '350px', color: 'black' }}>
                    <h4 style={{marginBottom: '7px', color: 'white', fontWeight: '400'}}>Su pedido fue recibido. </h4>
                    <p>Recuerde revisar su correo electrónico dónde le enviaremos los detalles de su pedido</p>
                    <button>
                        Ver mi pedido
                    </button>
                </Box>
            </Backdrop>

        </>);

    }

    return (<>

        <Backdrop
            sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <Box sx={{ width: '350px', color: 'black' }}>
                <h4 style={{marginBottom: '7px', color: 'white', fontWeight: '400'}}>Estamos creando su pedido...</h4>
                <LinearProgress sx={{
                    bgcolor: "white",
                }} variant="indeterminate" color="inherit" />
            </Box>
        </Backdrop>

    </>)

}

export default SubmitingOrderSkeletonComponent;