import ProductAPIContract from "../services/ProductAPIContract";
import { IProduct } from '../dto/ProductDTO'


export interface IProductRepository {
    list(page:number): any
    detail(product_id:number):any
}


abstract class ACProductRepository implements IProductRepository {

    constructor(protected api:ProductAPIContract){

    }

    abstract list(page:number):Promise<Array<IProduct>>
    abstract detail(product_id:number):IProduct
}

export default ACProductRepository