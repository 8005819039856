import React, {useState, useEffect} from 'react';
/* import { useHistory } from "react-router-dom"; */
import { Link } from 'react-router-dom';

/* import ListSubheader from '@mui/material/ListSubheader'; */
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
/* import ListItemIcon from '@mui/material/ListItemIcon'; */
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
/* import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send'; */
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
/* import StarBorder from '@mui/icons-material/StarBorder'; */
import { useLocation } from "react-router-dom";


interface MenuMobileProps {
    menuItems: Array<any>;
}

const MenuMobile = (props:MenuMobileProps) => {

    const [open, setOpen] = useState(false);
    const { pathname } = useLocation();
    /* const history = useHistory(); */
    const { menuItems } = props;

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        setOpen(!open);
      }, [pathname]);

    /* const goTo = (url:string) => {
        // use react router to go to the url
        history.push(url);
    } */

    return (
        <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        /* subheader={
            <ListSubheader component="div" id="nested-list-subheader">
            Nested List Items
            </ListSubheader>
        } */
        >
        <ListItemButton>
            {/* <ListItemIcon>
                <SendIcon />
            </ListItemIcon> */}
            <ListItemText primary={<Link className='mobile-nav-link' to="/account/">Mi Perfil</Link>} />
        </ListItemButton>
        <ListItemButton>
            {/* <ListItemIcon>
                <DraftsIcon />
            </ListItemIcon> */}
            <ListItemText primary={<Link className='mobile-nav-link' to="/account/orders/">Mis pedidos</Link>} />
        </ListItemButton>
        <ListItemButton onClick={handleClick}>
            {/* <ListItemIcon>
                <InboxIcon />
            </ListItemIcon> */}
            <ListItemText className='mobile-nav-link' primary="Categorías de producto" />
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            {menuItems.map((item, index) => (
            <ListItemButton key={`menu-${index}`}>
                {/* <ListItemIcon>
                    <StarBorder />
                </ListItemIcon> */}
                <ListItemText primary={<Link className='mobile-nav-link' to={item.to}>{item.text}</Link>} sx={{ pl: 4 }}/>
            </ListItemButton>
            ))}
            </List>
        </Collapse>
        <ListItemButton>
            {/* <ListItemIcon>
                <DraftsIcon />
            </ListItemIcon> */}
            <ListItemText primary={<Link className='mobile-nav-link' to="/account/logout/">Cerrar sesión</Link>} />
        </ListItemButton>
        </List>
    );

}

export default MenuMobile;