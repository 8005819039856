import axios from "axios";
import { getRequestsHeaders } from "../../../Common/infrastructure/providers/auth/firebase/firebase";
import { refreshAccessToken, firestore } from "../../../Common/infrastructure/providers/auth/firebase/firebase";


export const viewUserInformationUseCase = async (id:string) => {

    try {

        if(id === undefined){
            throw new Error("Cannot get UID from localstorage")
        }

        await refreshAccessToken();

        try {
            const firestoreReader = await firestore.collection("users").doc(id).collection('personal_info').doc('profile').get();
            const firestoreUserData:any = firestoreReader.data();

            /* console.log("[viewUserInformationUseCase] User data from firestore", firestoreUserData); */

            /* console.log("firestoreUserData?.name", firestoreUserData?.name)
            console.log("firestoreUserData?.email", firestoreUserData?.email)
            console.log("firestoreUserData?.phone", firestoreUserData?.phone)
            console.log("firestoreUserData?.userProviderID", firestoreUserData?.userProviderID)
            console.log("firestoreUserData?.rut", firestoreUserData?.rut) */

            if(firestoreUserData?.name !== undefined && firestoreUserData?.lastname !== undefined && firestoreUserData?.email && firestoreUserData?.phone !== undefined && firestoreUserData?.userProviderID && firestoreUserData?.rut !== undefined){
                
                const returnData = {
                    "Nombre": firestoreUserData?.name,
                    "Apellido": firestoreUserData?.lastname || '', 
                    "Email": firestoreUserData?.email, 
                    "RUT": firestoreUserData?.rut, 
                    "Telefono": firestoreUserData?.phone, 
                    "SuscritoNewsletter": firestoreUserData?.newsletterInscription, 
                    "ComoConocioSusaron": firestoreUserData?.comoConocioSusaron, 
                    "TipoCliente" : firestoreUserData?.clientType, 
                    "DatosCompletos": firestoreUserData?.DatosCompletos,
                    "userProviderID": firestoreUserData?.userProviderID,
                    "UltimaCiudadUtilizada": firestoreUserData?.UltimaCiudadUtilizada||'',
                    "UltimaComunaUtilizada": firestoreUserData?.UltimaComunaUtilizada||'',
                    "UltimaDireccionUtilizada": firestoreUserData?.UltimaDireccionUtilizada||'',
                    "UltimoNumeroDireccionUtilizada": firestoreUserData?.UltimoNumeroDireccionUtilizada||'',
                    "UltimoInfoExtraDireccionUtilizada": firestoreUserData?.UltimoInfoExtraDireccionUtilizada||'',
                    "UltimaReferenciaDireccionUtilizada": firestoreUserData?.UltimaReferenciaDireccionUtilizada||'',

                };

                /* console.log("returnData", returnData); */

                if(!Object.values(returnData).includes(undefined)){
                    /* console.log("User data from firestore", returnData); */
                    return returnData;
                }

            } 

        } catch(errorFirestore) {
            console.error("Error from firestore", errorFirestore);
        }

        const URL = `${process.env.REACT_APP_VIEW_USER_INFORMATION}/${id}/`;
        const headers = getRequestsHeaders();
        const userInformation  = await axios.get(URL, headers).then(res => res).catch(e => {
            throw new Error(e);
        })

        /* console.log("User data from Api GW", userInformation.data); */

        /* return {
            Nombre: userInformation.data.name,
            Apellido: userInformation.data.lastname,
            Nombre: userInformation.data.name,
            Nombre: userInformation.data.name,
        } */

        return userInformation.data;

    } catch(e) {

        console.error(e);
        // alert("Hubo un problema al obtener tus datos personales");
        return {};

    }

}