import React, {Reducer} from 'react';
import {IShoppingCart} from '../Cart/domain/contract/ShoppingCart';
import { IProductCatalog } from '../Product/domain/contracts/IProductCatalog';

interface IShoppingCartActions {
    type: string,
    payload: object
}

interface ICatalogActions {
    type: string,
    payload: object
}

interface IUserAuthenticationActions {
    type: string,
    payload: IUserAuth
}

interface shoppingCartReducer {
    shoppingCart:IShoppingCart,
    shoppingCartDispatch(action:IShoppingCartActions):IShoppingCart
}


interface productCatalogReducer {
    catalog:IProductCatalog,
    catalogDispatch(action:ICatalogActions):IProductCatalog
}


export interface IUserAuth {
    accessToken:string|null,
    uid: string|null,
    displayName: string|null,
    email: string|null,
    phoneNumber: string|null,
    photoURL:string|null
}

interface AuthenticationReducer {
    user:IUserAuth,
    userAuthDispatch(action:IUserAuthenticationActions):IUserAuth
}


interface IStore {
    shoppingCart: shoppingCartReducer,
    catalog: productCatalogReducer,
    auth:AuthenticationReducer,
}


export const StoreContext = React.createContext({} as IStore)
export const ADD_TO_CART_ACTION:string = 'setCart';


const AppContextProvider: React.FC = ({ children }) => {

    const shoppingCartReducer:Reducer<IShoppingCart,IShoppingCartActions> = (state, action):IShoppingCart => {
      
        const payload:any = action.payload;
        const isShoppingCart = (object: any): object is IShoppingCart => {
            return object.hasOwnProperty('products')
        }

        switch(action.type){
      
            case ADD_TO_CART_ACTION:   

                if(isShoppingCart(payload)) {
                    return payload as IShoppingCart;
                }

                /* console.log(state); */

                return state;
      

            case 'EMPTY_CART':

                if(isShoppingCart(payload)) {
                    /* console.log(payload) */
                    return payload as IShoppingCart;
                }

                return state;
            

          default:
            return state;
      
        }
      
    }

    const isProductCatalog = (object: any): object is IProductCatalog => {
        return object.hasOwnProperty('list')
    }

    const productCatalogReducer:Reducer<IProductCatalog,ICatalogActions> = (state, action):IProductCatalog => {

        switch(action.type) {

            case 'FILTER_CURRENT_CATALOG':

                const payload:any = action.payload;
                const catalog:IProductCatalog = payload.catalog;
                const filterCategory:string = payload.filter_category;
    
                if(!isProductCatalog(catalog)) {
                    return state;
                }

                const newCatalog = catalog.list.filter((prod) => {
                    return prod.category.category_slug === filterCategory;
                });

                return {
                    list: catalog.list,
                    viewing: newCatalog

                } as IProductCatalog;


            case 'SET_CATALOG':
    
                if(!isProductCatalog(action.payload)) {
                    /* console.log("No es un catálogo válido"); */
                    return state;
                }

                // console.log(action.payload);
                return action.payload as IProductCatalog;

            default:
                return state;

        }

    }

    const userAuthenticationReducer:Reducer<IUserAuth,IUserAuthenticationActions> = (state, action):IUserAuth => {

        switch(action.type) {

            case 'SET_USER':
                return action.payload;

            case 'LOGOUT':    
                return {
                    accessToken:null,
                    uid:null,
                    displayName:null,
                    email:null,
                    phoneNumber:null,
                    photoURL:null
                }

            default:
                return state;
        
        }

    }


    const productsInCart:IShoppingCart = {products:[],totalPrice:0, totalProducts:0};

    const emptyInitialUser = {
        accessToken:null,
        uid:null,
        displayName:null,
        email:null,
        phoneNumber:null,
        photoURL:null
    }

    const savedUserJSONString:string|null = localStorage.getItem('userInfo');
    let savedUser = null;

    if(savedUserJSONString){
        savedUser = JSON.parse(savedUserJSONString);
    }

    const initialUser = savedUser ? savedUser : emptyInitialUser;

    const [shoppingCart, shoppingCartDispatch] = React.useReducer(shoppingCartReducer, productsInCart)
    const [catalog, catalogDispatch] = React.useReducer(productCatalogReducer, {list:[], viewing:[]})
    const [user, userAuthDispatch] = React.useReducer(userAuthenticationReducer, initialUser)

    const store:any = {
        shoppingCart: {shoppingCart, shoppingCartDispatch},
        catalog: {catalog, catalogDispatch},
        auth: {user, userAuthDispatch},
    }

    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>

}

export default AppContextProvider