import React, {useState, FC, useEffect} from "react";
import { useHistory } from "react-router-dom";
import InputComponent from "../atoms/Input";
import { IInput } from "../atoms/Input";
import { getUserId } from "../../../../Clients/application/useCases/getUserIdUseCase";
import { viewUserInformationUseCase } from "../../../../Clients/application/useCases/viewUserInformation";
import { onAuthStateChange, refreshAccessToken, auth} from "../../providers/auth/firebase/firebase";
import { UpdateUserInformationUseCase } from "../../../../Clients/application/useCases/updateUserInformation";
import RutInputComponent from "../../../../Clients/infraestructure/components/molecules/RutInputComponent";
import { IRutInputComponent } from "../../../../Clients/infraestructure/components/molecules/RutInputComponent";
/* import Snackbar from '@mui/material/Snackbar'; */

/* import Button from '@mui/material/Button'; */
import Snackbar from '@mui/material/Snackbar';
/* import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'; */
import ClientPersonalDataSkeletonComponent from './ClientPersonalDataSkeleton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import SubmitingRegistrationProgress from "./SubmitingRegistrationProgress";

const cacheUserData = (userData:any) => {
    localStorage.setItem('cachedUserInfo', JSON.stringify(userData));
}


const ClientPersonalDataComponent:FC = (props:any) => {

    // const {auth} = useContext(StoreContext);
    let history = useHistory();
    const isCompletingSignUp = localStorage.getItem('isCompletingSignUp');

    const [userIsLoggedInAndReady, setUserIsLoggedInAndReady] = useState(false);

    const [rut, setRut] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [comoConocioSusaron, setComoConocioSusaron] = useState('');
    const [newsletterInscription, setNewsletterInscription] = useState(false);
    const [clientType, setClientType] = useState('Natural');

    const [isLoadingUserData, setIsLoadingUserData] = useState(true);
    const [showBackdrop, setShowBackdrop] = useState(true);
    const [isCreatingNewUser, setIsCreatingNewUser] = useState(false);

    /* const rutFieldProps:IInput = {
        fieldValue: rut,
        onChangeFunc:setRut
    } */

    const rut2FieldProps:IRutInputComponent = {
        rutState: rut,
        rutOnChangeHandler:setRut
    }

    const nameFieldProps:IInput = {
        fieldValue: name,
        onChangeFunc:setName
    }

    const lastnameFieldProps:IInput = {
        fieldValue:lastname,
        onChangeFunc:setLastname
    }

    const phoneFieldProps:IInput = {
        fieldValue:phone,
        onChangeFunc:setPhone
    }

    const comoConocioSusaronFieldProps:IInput = {
        fieldValue:comoConocioSusaron,
        onChangeFunc:setComoConocioSusaron
    }


    const [openSavePersonalDataResult, setOpenSavePersonalDataResult] = useState(false);

    const handleClose = (event:any, reason:string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSavePersonalDataResult(false);
    };

    const updateUserInformation = async () => {

        try {

            const userId = getUserId();

            if(!userId) {
                return false;
            }

            setIsLoadingUserData(true);
            window.scroll(0,0);

            if(isCompletingSignUp === '0'){
                setShowBackdrop(true);
            }

            if(isCompletingSignUp === '1'){
                setIsCreatingNewUser(true);
            }

            console.log("isCompletingSignUp", isCompletingSignUp);

            let _isCompletingSignUp = isCompletingSignUp;

            const payload = {
                rut, 
                name,
                email,
                lastname,
                phone,
                "comoConocioSusaron": comoConocioSusaron || '',
                "newsletterInscription": newsletterInscription || false,
                clientType
            }

            await UpdateUserInformationUseCase(userId, payload);

            const info = await viewUserInformationUseCase(userId);
            const {DatosCompletos} = info;
            let cachePayload:any = {};

            if(DatosCompletos) {
                
                
                localStorage.setItem('showCheckoutIncompleData', "0");
                //_isCompletingSignUp = "0";
                
                const {Nombre,Apellido, Email, RUT, Telefono, SuscritoNewsletter, ComoConocioSusaron, TipoCliente} = info;

                cachePayload = {
                    rut: RUT, 
                    name: Nombre,
                    email: Email,
                    lastname: Apellido,
                    phone: Telefono,
                    comoConocioSusaron: ComoConocioSusaron||'',
                    newsletterInscription: SuscritoNewsletter||'',
                    clientType: TipoCliente,
                    DatosCompletos
                }

                console.log("Return data without entering setInterval", cachePayload);
                localStorage.setItem('cachedUserInfo', JSON.stringify(cachePayload));
                
                setIsLoadingUserData(false);
                setShowBackdrop(false);
                setOpenSavePersonalDataResult(true);
                setIsCreatingNewUser(false);
                // firebauth?.currentUser?.reload()

                const postLoginRedirection = localStorage.getItem('postLoginRedirection');

                if(postLoginRedirection !== null && postLoginRedirection !== "") {
                    console.log("postLoginRedirection !== null && DatosCompletos === true")
                    localStorage.removeItem('postLoginRedirection');
                    localStorage.setItem('isCompletingSignUp', "0");
                    return history.push(postLoginRedirection);
                }

                if(DatosCompletos === true && isCompletingSignUp === '1'){
                    console.log("DatosCompletos === true && isCompletingSignUp === '0'")
                    localStorage.setItem('isCompletingSignUp', "0");
                    return history.push('/productos/');

                } else {
                    console.log("todo ok")
                    localStorage.setItem('isCompletingSignUp', "0");
                    setOpenSavePersonalDataResult(true);

                    if(isCompletingSignUp === "1") {
                        return history.push('/productos/');
                    }
                    
                }

                return cachePayload;

            }

            const userSessionData:any = JSON.parse(localStorage.getItem('userInfo')||'{}');
            let i = 0;
            let maxRetry = 50;
            let timerToCheckIfUserExists = setInterval( async function() {

                i++;

                if(i >= maxRetry){
                    setIsLoadingUserData(false);
                    setShowBackdrop(false);
                    clearInterval(timerToCheckIfUserExists);
                    return;
                }

                if(i < maxRetry){
                    const userData = await viewUserInformationUseCase(userSessionData["uid"]);
                    if(userData){

                        const {Nombre,Apellido, Email, RUT, Telefono, SuscritoNewsletter, ComoConocioSusaron, TipoCliente, DatosCompletos} = userData;

                        if(!Nombre) {
                            console.log("Nombre failed", Nombre);
                            return;
                        }

                        if(!Apellido) {
                            console.log("Apellido failed", Apellido);
                            return;
                        }
                        
                        if(!Email) {
                            console.log("Email failed", Email);
                            return;
                        }

                        if(!RUT) {
                            console.log("RUT failed", RUT);
                            return;
                        }

                        if(!Telefono) {
                            console.log("Telefono failed", Telefono);
                            return;
                        }


                        cachePayload = {
                            rut: RUT, 
                            name: Nombre,
                            email: Email,
                            lastname: Apellido,
                            phone: Telefono,
                            comoConocioSusaron: ComoConocioSusaron||'',
                            newsletterInscription: SuscritoNewsletter||'',
                            clientType: TipoCliente,
                            DatosCompletos
                        }

                        console.log("cachePayload", cachePayload);

                        localStorage.setItem('cachedUserInfo', JSON.stringify(cachePayload));
                        
                        cacheUserData(cachePayload);
                        setIsLoadingUserData(false);
                        setShowBackdrop(false);

                        const postLoginRedirection = localStorage.getItem('postLoginRedirection');
                        
                        if(postLoginRedirection !== null && DatosCompletos === true) {
                            console.log("#2 postLoginRedirection !== null && DatosCompletos === true")
                            localStorage.removeItem('postLoginRedirection');
                            /* localStorage.setItem('isCompletingSignUp', "0"); */
                            history.push(postLoginRedirection);
                            clearInterval(timerToCheckIfUserExists);
                        }

                        if(DatosCompletos === true && _isCompletingSignUp === '1'){
                            console.log("#2 DatosCompletos === true && isCompletingSignUp === '1'")
                            history.push('/productos/');
                            /* localStorage.setItem('isCompletingSignUp', "0"); */
                            clearInterval(timerToCheckIfUserExists);

                        } else {
                            console.log("#2 todo ok")
                            setOpenSavePersonalDataResult(true);
                            localStorage.setItem('isCompletingSignUp', "0");
                            history.push('/account/');
                            clearInterval(timerToCheckIfUserExists);
                        }

                    }
                }

            }, 1000);

            /* cacheUserData(cachePayload);
            setIsLoadingUserData(false);
            setOpenSavePersonalDataResult(true);
            localStorage.setItem('isCompletingSignUp', "0"); */

            /* if(isCompletingSignUp === '1'){
                history.push('/productos');
            } */
            

        } catch(e) {

            console.error("updateUserInformation", e);

        }

    }

    useEffect(() => {
        
        const unsubscribe = onAuthStateChange(setUserIsLoggedInAndReady);
        
        return () => {
            unsubscribe();
        };

    }, []);


    useEffect(()=>{

        const getUsrInformation = async () => {
            
            const userId = getUserId();

            if(!userId || auth.currentUser?.uid !== userId) {
                history.push('/account/logout');
                return false;
            }

            try {
                const cachedUserData:any = JSON.parse(localStorage.getItem('cachedUserInfo')||'');
                console.log("cachedUserData", cachedUserData);

                console.log("auth.currentUser", auth.currentUser?.uid);

                if(cachedUserData?.rut && cachedUserData?.name && cachedUserData?.lastname && cachedUserData?.email && cachedUserData?.phone && cachedUserData?.comoConocioSusaron !== undefined && cachedUserData?.newsletterInscription !== undefined && cachedUserData?.clientType && cachedUserData?.DatosCompletos !== undefined) {

                    // To check if cached data belongs to users logged in
                    const accessTokens = await refreshAccessToken();
                    if(!accessTokens) {
                        history.push('/');
                        return false;
                    }

                    setRut(cachedUserData.rut);
                    setName(cachedUserData.name);
                    setLastname(cachedUserData.lastname);
                    setEmail(cachedUserData.email);
                    setPhone(cachedUserData.phone);
                    setComoConocioSusaron(cachedUserData.comoConocioSusaron||'');
                    setNewsletterInscription(cachedUserData.newsletterInscription||'');
                    setClientType(cachedUserData.clientType);

                    if(cachedUserData.DatosCompletos) {
                        localStorage.setItem('isCompletingSignUp', "0");
                    } else {
                        console.log("cachedUserData.DatosCompletos", cachedUserData.DatosCompletos);
                    }

                    setIsLoadingUserData(false);
                    return true;
                }

            } catch(err) {
                console.error("No cached user info", err);
            }


            console.log("Cargando datos del usuario desde API");
            const info = await viewUserInformationUseCase(userId);
            const {Nombre,Apellido, Email, RUT, Telefono, SuscritoNewsletter, ComoConocioSusaron, TipoCliente, DatosCompletos} = info;

            const cachePayload = {
                rut: RUT, 
                name: Nombre,
                email: Email,
                lastname: Apellido,
                phone: Telefono,
                comoConocioSusaron: ComoConocioSusaron,
                newsletterInscription: SuscritoNewsletter,
                clientType: TipoCliente,
                DatosCompletos
            }

            cacheUserData(cachePayload);
            
            setName(Nombre);
            setLastname(Apellido);
            setEmail(Email);
            setRut(RUT);
            setPhone(Telefono);
            setComoConocioSusaron(ComoConocioSusaron);
            setNewsletterInscription(SuscritoNewsletter);
            setClientType(TipoCliente);

            if(DatosCompletos) {
                localStorage.setItem('isCompletingSignUp', "0");
            } else {
                localStorage.setItem('isCompletingSignUp', "1");
            }

            setIsLoadingUserData(false);
            return true;
        }

        if(userIsLoggedInAndReady){
            getUsrInformation();
        } else {
            console.log("El usuario no esta logueado");
        }

        return () => {}

    },[userIsLoggedInAndReady]);

    const showCheckoutIncompleData = localStorage.getItem('showCheckoutIncompleData');
    //const showBackToCheckoutInAccountPage = localStorage.getItem('showBackToCheckoutInAccountPage');

    if(isLoadingUserData) {
        return (<>
            {showBackdrop && <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={showBackdrop}
                                onClick={e => setShowBackdrop(false)}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
            }

            <ClientPersonalDataSkeletonComponent />
        </>)
    }
    
    return (
        <>

        {isCreatingNewUser && <SubmitingRegistrationProgress ending={true} />}

            <Snackbar
                sx = {{mt: '120px'}}
                open={openSavePersonalDataResult}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Su información fue guardada exitosamente."
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
        
            <div className="checkout-input checkbox">

                {isCompletingSignUp === '1' && <h2>Finaliza tu registro ingresando tus datos personales.<br/></h2>}

                {showCheckoutIncompleData === '1' && <div className="incomplete-user-data-warning">
                    <Alert variant="outlined" severity="info" sx={{ borderColor: "#ddd" }}>
                        Para poder realizar pedidos en Susaron, por favor completa los siguientes datos.
                    </Alert>
                </div>}
                
                <label>¿Ud. es?</label>
                
                <span>
                    <input onChange={e=>setClientType(e.target.value)} type="radio" name="client-type" value="Persona natural" defaultChecked /> Persona
                </span>

                <span>
                    <input onChange={e=>setClientType(e.target.value)} type="radio"  name="client-type" value="Empresa" /> Empresa
                </span>

            </div>

            <div className='account-data-input'>
                <label>Nombre</label>
                <InputComponent {...nameFieldProps} />
            </div>

            <div className='account-data-input'>
                <label>Apellido</label>
                <InputComponent {...lastnameFieldProps} />
            </div>

            <div className='account-data-input'>
                <label>RUT</label>
                {/* <InputComponent {...rutFieldProps} /> */}
                <RutInputComponent {...rut2FieldProps} />
            </div>

            <div className='account-data-input'>
                <label>Teléfono</label>
                <InputComponent {...phoneFieldProps} />
            </div>

            <div className="checkbox">
                <span>
                    <input checked={newsletterInscription} onChange={e=>setNewsletterInscription(e.target.checked)} type="checkbox" name="client-suscribes-email" /> Quiero recibir correos con ofertas
                </span>
            </div>

            <div className="checkout-input">
                <label>¿Cómo conoció Susaron?</label>
                <InputComponent {...comoConocioSusaronFieldProps} />
            </div>

            <button onClick={e=>updateUserInformation()}>
                {isCompletingSignUp === '1' && <>Completar mi información</>}
                {isCompletingSignUp === '0' && <>Actualizar mi información</>}
            </button>

        </>
    )

}

export default ClientPersonalDataComponent;