import {IOrder} from '../domain/order';
import OrderAPIContract from '../services/OrderAPIContract';

interface IOrderRepository {
    list(page:number):Promise<Array<IOrder>>,
    place(order:IOrder):Promise<IOrder>,
    detail(product_id:number):Promise<IOrder>
}

export abstract class ACOrderRepository implements IOrderRepository {

    constructor(protected api:OrderAPIContract){

    }

    abstract list(page:number):Promise<Array<IOrder>>
    abstract place(order:IOrder):Promise<IOrder>
    abstract detail(order_id:number):Promise<IOrder>
}