export const getUserId = () => {

    const userInfo:any = localStorage.getItem('userInfo');

    if(!userInfo) {
        return 0;
    }

    const userInfoJSON = JSON.parse(userInfo);
    const id:string = userInfoJSON["uid"];

    return id;

}