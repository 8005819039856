import { ACOrderRepository } from "../../domain/repository/ACOrderRepository";
import { IOrder } from "../../domain/domain/order";
import { getRequestsHeaders } from "../../../Common/infrastructure/providers/auth/firebase/firebase";
export class APIOrderRepository extends ACOrderRepository {

    /* getRequestsHeaders():any {
        const userInfo:any = localStorage.getItem('userInfo');
        const userInfoJSON = JSON.parse(userInfo);
        const token = userInfoJSON["accessToken"];

        return {
            "headers": {
                "Authorization": `Bearer ${token}`
            }
        }
    } */

    async list(page:number):Promise<Array<IOrder>> {

        const headers = getRequestsHeaders();
        const orderList = await this.api.get('',headers)
        return orderList;
    }

    async place(order:IOrder):Promise<IOrder>{
        const headers = getRequestsHeaders();
        const placeOrder = await this.api.post(order,'',headers)
        return placeOrder;
    }

    async detail(order_id:number):Promise<IOrder> {
        const headers = getRequestsHeaders();
        const placeOrder = await this.api.get(``,headers)
        return placeOrder as IOrder;
    }

}