import React, {FC} from "react";
import Skeleton from '@mui/material/Skeleton';

const OrderDetailSkeletonComponent:FC = (props:any) => {

    const skeletonHeight = 10;

    return(<>

            <div className="order-container order-details">
                <h1>Detalle Orden</h1>

                <dl id="order-detail-list">
                    <dt>N° Orden</dt>
                    <dd><Skeleton sx={{ bgcolor: 'grey.500' }} height={skeletonHeight} variant="text" /></dd>
                    <dt>Fecha</dt>
                    <dd><Skeleton sx={{ bgcolor: 'grey.500' }} height={skeletonHeight} variant="text" /></dd>
                    <dt>Ciudad destino</dt>
                    <dd><Skeleton sx={{ bgcolor: 'grey.500' }} height={skeletonHeight} variant="text" /></dd>
                    <dt>Comuna destino</dt>
                    <dd><Skeleton sx={{ bgcolor: 'grey.500' }} height={skeletonHeight} variant="text" /></dd>
                    <dt>Dirección entrega</dt>
                    <dd><Skeleton sx={{ bgcolor: 'grey.500' }} height={skeletonHeight} variant="text" /></dd>
                    <dt>Estado de la orden</dt>
                    <dd><Skeleton sx={{ bgcolor: 'grey.500' }} height={skeletonHeight} variant="text" /></dd>
                    <dt>Total aprox.</dt>
                    <dd><Skeleton sx={{ bgcolor: 'grey.500' }} height={skeletonHeight} variant="text" /></dd>
                    <dt>Total despacho</dt>
                    <dd><Skeleton sx={{ bgcolor: 'grey.500' }} height={skeletonHeight} variant="text" /></dd>
                </dl>
                
                <table cellSpacing={0} id="order-detail-product-list">
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(7)].map( (product,key) => 
                            <tr key={key}>
                                <td><Skeleton sx={{ bgcolor: 'grey.500' }} height={skeletonHeight} variant="text" /></td>
                                <td><Skeleton sx={{ bgcolor: 'grey.500' }} height={skeletonHeight} variant="text" /></td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div className="spacer h30">&nbsp;</div>

                <div className="buttons">
                    <Skeleton sx={{ bgcolor: 'grey.500' }} width="50%" height={70} variant="text" />
                    <Skeleton sx={{ bgcolor: 'grey.500' }} width="50%" height={70} variant="text" />
                </div>

            </div>
    
    </>)

}

export default OrderDetailSkeletonComponent;