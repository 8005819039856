import React, {FC, useContext, useState, useEffect} from 'react';
import { Providers, SignInWithSocialMedia, loginWithEmailAndPassword } from '../../providers/auth/firebase/firebase';
import { IUserAuth, StoreContext } from '../../../../store/AppContent';
import IImageComponent, {IImage} from '../atoms/Image';
import { useHistory } from "react-router-dom";
import InputComponent from '../atoms/Input';
import { IInput } from '../atoms/Input';
import { Link } from 'react-router-dom';
import { viewUserInformationUseCase } from '../../../../Clients/application/useCases/viewUserInformation';
/* import SubmitingRegistrationProgress from './SubmitingRegistrationProgress'; */

// TODO Sacar, esto no va aquí
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SubmitingRegistrationProgress from './SubmitingRegistrationProgress';

const LoginOptionsComponent:FC<any> = (props:any) => {

    const {auth} = useContext(StoreContext);
    let history = useHistory();

    const googleIcon:IImage = {
        alt:"Entrar con Gmail",
        height: 32,
        width: 32,
        src:"/static/google-icon.jpg",
    }

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [submiting, setSubmiting] = useState<boolean>(false);
    const [creatingNewUser, setCreatingNewUser] = useState<boolean>(false);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [userIsCreatedIntoAPI, setUserIsCreatedIntoAPI] = useState<boolean>(false);
    
    let i = 0;
    let maxRetry = 30;

    useEffect(() => {    

        if(isLoggedIn) {
            
            console.log("User is logged in... check if created into api");
            const userSessionData:any = JSON.parse(localStorage.getItem('userInfo')||'{}');
    
            let timerToCheckIfUserExists = setInterval( async function() {

                i++;

                if(i >= maxRetry){
                    setSubmiting(false);
                    setUserIsCreatedIntoAPI(false);
                    clearInterval(timerToCheckIfUserExists);
                    return;
                }

                if(i < maxRetry){
                    const userData = await viewUserInformationUseCase(userSessionData["uid"]);

                    console.log("User data", userData);

                    if(userData){
                        
                        const {Nombre,Apellido, Email, RUT, Telefono, SuscritoNewsletter, ComoConocioSusaron, TipoCliente, DatosCompletos} = userData;

                        if(Nombre === undefined) {
                            console.log("Nombre failed", Nombre);
                            return;
                        }

                        if(Apellido === undefined) {
                            console.log("Apellido failed", Apellido);
                            return;
                        }
                        
                        if(!Email) {
                            console.log("Email failed", Email);
                            return;
                        }

                        if(RUT === undefined) {
                            console.log("RUT failed", RUT);
                            return;
                        }

                        if(Telefono === undefined) {
                            console.log("Telefono failed", Telefono);
                            return;
                        }

                        const cachePayload = {
                            rut: RUT, 
                            name: Nombre,
                            email: Email,
                            lastname: Apellido,
                            phone: Telefono,
                            comoConocioSusaron: ComoConocioSusaron,
                            newsletterInscription: SuscritoNewsletter,
                            clientType: TipoCliente
                        }

                        localStorage.setItem('cachedUserInfo', JSON.stringify(cachePayload));

                        if(DatosCompletos === true) {
                            localStorage.setItem('isCompletingSignUp', "0");
                        } else {
                            localStorage.setItem('isCompletingSignUp', "1");
                        }
                        
                        setUserIsCreatedIntoAPI(true);
                        clearInterval(timerToCheckIfUserExists);
                    }
                }

            }, 1000);
        }

        return () => {}

    },[isLoggedIn]);

    useEffect(() => {

        if(userIsCreatedIntoAPI) {
            
            const postLoginRedirection = localStorage.getItem('postLoginRedirection');
            const isCompletingSignUp = localStorage.getItem('isCompletingSignUp');
            const cachedUserInfo = localStorage.getItem('cachedUserInfo');

            console.log("cachedUserInfo", cachedUserInfo);

            if(postLoginRedirection !== null && isCompletingSignUp === "0") {
                localStorage.removeItem('postLoginRedirection');
                history.push(postLoginRedirection);
                setSubmiting(false);
                return;
            }

            setSubmiting(false);
            
            if(isCompletingSignUp === "0") {
                return history.push("/productos/");
            }

            return history.push("/account/");
        }

        return () => {}

    },[userIsCreatedIntoAPI]);


    const setLoggedUser = (payload:IUserAuth) => {

        if(payload.accessToken !== null){
            
            auth.userAuthDispatch({
                type: 'SET_USER',
                payload : payload
            });

            // TODO Mover a un caso de uso
            localStorage.setItem('userInfo',JSON.stringify(payload));

        }
    }

    // TODO Llevar a un useCase con su respectivo adaptador
    const saveNewUser = async (payload:IUserAuth, authHeader:string) => {

        try {
            
            const URL:string = process.env.REACT_APP_EMAIL_PASSWORD_REGISTER || '';
            const userCreatingPayload = {name:'', username:'', password: '', ...payload}
            
            return await axios.post(URL, userCreatingPayload, {
                
                headers: {
                    "Authorization": `Bearer ${authHeader}`
                }

            }).then(res => res.data).catch(e => {

                throw new Error(e);
                
            });

        } catch(e) {

            console.error("Error al guardar el usuario recien creado", e)

        }

    }

    const GMailLogin = async () => {
        
        try {

            setSubmiting(true);

            await SignInWithSocialMedia(Providers.google).then(async (res:any) => {
                
                /* console.log("is new user: ", res.additionalUserInfo.isNewUser); */

                /* if(res.additionalUserInfo.isNewUser) {

                } */

                if(!res.user) {
                    setSubmiting(false);
                    return {}
                }

                if(!res.user?._delegate?.accessToken === null || !res.user?._delegate?.accessToken === undefined) {
                    setSubmiting(false);
                    return {}
                }

                const {uid, displayName, email,phoneNumber, photoURL} = res.user;
                
                const loginResultData = {

                    "accessToken": res.user._delegate.accessToken, 
                    uid, 
                    displayName, 
                    email, 
                    phoneNumber, 
                    photoURL
                };

                if(!res?.additionalUserInfo?.isNewUser === undefined) {
                    /* console.log("El sistema no dijo si es un usuario nuevo o ya registrado previamente"); */
                    setSubmiting(false);
                    return {}
                }

                if(res?.additionalUserInfo?.isNewUser === true) {

                    /* console.log("User is new, creating into API"); */
                    await saveNewUser(loginResultData, loginResultData?.accessToken).then(resSaveUser => {
                        return resSaveUser;
                    }).catch(err => {
                        setSubmiting(false);
                        throw new Error(err);
                    });

                    /* console.log("saveNewUserResult", saveNewUserResult); */

                }

                setLoggedUser(loginResultData);
                setIsLoggedIn(true);
                return loginResultData
            
            }).catch(err => {

                alert("Hubo un problema al ingresar a su cuenta de Google");                
                setIsLoggedIn(false);
                setSubmiting(false);
                return err;
            });
            
            /* const {uid,displayName,email,phoneNumber,photoURL} = loginResult.user; */
            /* console.log("loginResult", loginResult); */
            /* console.log(Object.keys(loginResult)); */

            

            //localStorage.setItem('isCompletingSignUp', "0");

            /* const postLoginRedirection = localStorage.getItem('postLoginRedirection');
            if(postLoginRedirection !== null) {
                localStorage.removeItem('postLoginRedirection');
                history.push(postLoginRedirection);
                setSubmiting(false);
                return;
            }

            setSubmiting(false);
            history.push("/account/"); */


        } catch(e) {

            setSubmiting(false);
            console.log(e)
        }
    }

    

    const userPasswordLogin = async() => {

        try {
            
            setSubmiting(true);

            await loginWithEmailAndPassword(username, password).then( async (res:any) => {

                const uid:string|undefined = res?.user?.uid;
                const email = res?.user?.email;
                const accessToken:string|undefined = await res?.user?.getIdToken();

                const displayName = '';
                const phoneNumber = '';
                const photoURL = '';

                if(!uid|| !email || !accessToken) {
                    throw new Error("Hubo un problema técnico al registrar tu cuenta");
                }

                /* const userInfoPayload = {accessToken, uid, displayName, email, phoneNumber, photoURL}; */

                const loginResultData = {
                    "accessToken": res.user._delegate.accessToken, 
                    uid, 
                    displayName, 
                    email, 
                    phoneNumber, 
                    photoURL
                };

                if(!res?.additionalUserInfo?.isNewUser === undefined) {
                    console.log("El sistema no dijo si es un usuario nuevo o ya registrado previamente");
                    setSubmiting(false);
                    return {}
                }

                if(res?.additionalUserInfo?.isNewUser === true) {

                    console.log("User is new, creating into API");
                    const saveNewUserResult = await saveNewUser(loginResultData, loginResultData?.accessToken).then(resSaveUser => {
                        return resSaveUser;
                    }).catch(err => {
                        setSubmiting(false);
                        throw new Error(err);
                    });

                    console.log("saveNewUserResult", saveNewUserResult);

                }

                setLoggedUser(loginResultData);
                setIsLoggedIn(true);
                return loginResultData


            }).catch(err =>{

                alert("Hubo un problema al ingresar a su cuenta.");                
                setIsLoggedIn(false);
                setSubmiting(false);
                return err;

            });

            /* const uid:string|undefined = loginResult?.user?.uid;
            const email = loginResult?.user?.email;
            const accessToken:string|undefined = await loginResult?.user?.getIdToken();

            const displayName = '';
            const phoneNumber = '';
            const photoURL = '';

            if(!uid|| !email || !accessToken) {
                throw new Error("Hubo un problema técnico al registrar tu cuenta");
            }

            const userInfoPayload = {accessToken, uid, displayName, email, phoneNumber, photoURL};

            setLoggedUser(userInfoPayload);
            localStorage.setItem('isCompletingSignUp', "0");
            
            const postLoginRedirection = localStorage.getItem('postLoginRedirection');
            if(postLoginRedirection !== null) {
                localStorage.removeItem('postLoginRedirection');
                history.push(postLoginRedirection);
                setSubmiting(false);
                return;
            }

            setSubmiting(false);
            history.push("/account/"); */

        } catch(e:any) {

            setSubmiting(false);
            const errorCode = e["code"];

            if(errorCode === 'auth/user-not-found') {
                return alert("La cuenta no existe.")
            }

            if(errorCode === 'auth/wrong-password') {
                return alert("La contraseña es incorrecta.")
            }

            console.error(errorCode);
            alert("Hubo un problema al ingresar a tu cuenta.");

        }

    }

    const usernameFieldProps:IInput = {
        fieldValue: username,
        onChangeFunc:setUsername
    }

    const passwordFieldProps:IInput = {
        fieldValue: password,
        onChangeFunc:setPassword,
        inputType: 'password'
    }

    /* if((submiting && creatingNewUser)) {
        return (
            <SubmitingRegistrationProgress />
        )
    } */

    /* if(submiting) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    } */
    
    return (
    <>

        {submiting && creatingNewUser && <SubmitingRegistrationProgress />}

        {submiting && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>}

        <div id="login-options-container">

            <h2 className="auth-login-message">Ingresar a tu cuenta</h2>
            <p className="auth-login-message">Por favor selecciona una de las siguientes opciones para ingresar.</p>

            <div className='social-login'>
                <button className="social-login-btn" onClick={e=>GMailLogin()}>
                    <IImageComponent {...googleIcon} />
                    Gmail
                </button>
            </div>

            <div id="custom-login-form">

                <div className='input-login'>
                    <label>Email</label>
                    <InputComponent {...usernameFieldProps} />
                </div>

                <div className='input-login'>
                    <label>Password</label>
                    <InputComponent {...passwordFieldProps} />
                </div>

                <button className='btn-login' onClick={e=> userPasswordLogin()}>
                    Entrar
                </button>

                {<Link to="/password-recovery">
                    <button className='btn-forgotten-password'>
                        Olvidé mi contraseña
                    </button>
                </Link>}

            </div>

            <div className='reponsive-signup-link-container'>


                <hr />
                
                <h4>
                    También puedes registrarte aqui con tu correo de cualquier proveedor.
                </h4>

                <Link className='btn-register light' to="/signUp">
                    Regístrate aquí
                </Link>

            </div>

            
        </div>

    </>)
}

export default LoginOptionsComponent